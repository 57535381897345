import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import UserContext from '../contexts/UserContext.js';
import BlockUI from 'react-block-ui';
import moment from 'moment';
import PacksHeader from '../components/PacksHeader';

// [x] JOHN D 
// clean this file and remove anything we don't need for the Packs Page

export default class PacksPage extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    if (!this.context.isLoggedIn()) {
      this.props.history.push("/login");
    }

  }

  render() {
    const { packs=[] } = this.context;
    const active = packs.filter(pack => pack.active)
    const used = packs.filter(pack => !pack.active && !pack.expired)
    const expired = packs.filter(pack => pack.expired)

    const display = [
      { id: "csa-active-packs", name: "Active Scan Pack", packs: active },
      { id: "csa-used-packs", name: "Used Scan Packs", packs: used },
      { id: "csa-expired-packs", name: "Expired Scan Packs", packs: expired },
    ];

    let success = null;
    let params = new URLSearchParams(this.props.location.search.substring(1));
    if (params.has("success")) success = params.get("success").replace(/#.*/, '');

    return (
      <>
        <PacksHeader />

        { success && <div className="alert alert-info">{success}</div> }

{!active.length && (
  <div id="no-packs-msg" className="text-center">
    <div><div class="alert alert-success csa-alert d-inline-block mt-0" role="alert">Your account does not have {packs.length ? "an Active" : "a" } Scan Pack.</div></div>
    <Link to="/packs/buy" className="btn btn-primary">Buy a Pack of Scans</Link>
  </div>
)}

{display.map (({id,name,packs})  => packs.length > 0 ? (
      <div id={id} className="csa-packs">
        <div className="row">
          <div className="col">
            <h3>{name}</h3>
{packs.map (pack => (
            <div key={pack.id} className="csa-pack-wrap" data-pack-id={pack.id}>
              <div aria-busy="false">
                <div className="csa-pack d-flex align-items-stretch active">
                  <div className="csa-scan-info d-flex align-items-center flex-wrap w-100">
                    <div className="d-flex flex-wrap flex-grow-1 px-1">
                      <div className="d-flex flex-wrap flex-grow-1 pb-2">
                        <div className="csa-scan-location flex-grow-1">
                          <h2>{pack.credits} Pack of 3 Minute Scans</h2>
                          <h4>Purchased {moment(pack.created_at).format('MMMM DD, YYYY')}</h4>
{pack.remaining > 0 && !pack.expired && (
                          <h6>Unused Scans will expire {moment(pack.expires_at).format('MMMM DD, YYYY')}</h6>
)}
                        </div>
                        <div className="csa-scan-date">
                          <h3>{pack.remaining} Scans Remaining</h3>
                        </div>
                      </div>
                    </div>
{pack.scans.length > 0 && (
                    <div className="d-flex flex-wrap w-100">
                      <div className="d-flex px-1">
                        <div>
                          Created Scans <br />
{pack.scans.map ((scan,idx) => (
                          <React.Fragment key={scan}>{idx+1}. {scan}<br /></React.Fragment>
))}
                        </div>
                      </div>
                    </div>
)}
                    </div>
                  </div>
                </div>
                <span></span>
              </div>
))}
            </div>
          </div>
        </div>
) : undefined
)}
      </>
     );
  }
}
