import React, { Component } from 'react';
import MembershipContext, { Steps } from '../contexts/MembershipContext.js';
import moment from 'moment';
import superagent from 'superagent';
import { withUser } from './UserProvider.js';
import { unique, flatten } from '../lib/Array';
import QS from 'query-string';
import { get, post, destroy } from '../ajax.js';

class MembershipProvider extends Component {
  constructor(props) {
    super(props);
    const qs = QS.parse(window.location.search);
    let camp = qs.camp || qs.c;
    this.state = {
      step: camp ? Steps.WHERE : Steps.START,
      price: null,
      subscription_type: null,
      consent: false
    }
  }

  onStep = (step, extraState={}) => {
    this.setState({ ...extraState, step });
  }

  onType = (type, price) => {
    this.setState({subscription_type: type, price: price})
  }

  onToggleConsent = () => {
    this.setState({consent: !this.state.consent})
  }

  canCheckout = () => {
    return this.haveMembership() && this.haveEmail() && this.haveConsent()
  }

  onCheckout = (token) => {
    const vars = {
      email: this.state.email,
      subscription_type: this.state.subscription_type,
      price: this.state.price,
      token: token
    }

    return superagent.post('/bot-backend/api/subscription').type('form').send(vars).then(({body}) => {
      console.log("onCheckout: got response = ", body);
      const { scan_id } = body;
      this.setState({ scan_id });
      return body;
    });
  }

  haveMembership = () => {
    return (this.state.subscription_type != null);
  }
  
  haveEmail = () => {
    return this.state.email;
  }

  getPrice = () => {
    return this.state.price;
  }

  haveConsent = () => {
    return this.state.consent;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let nextState = {};
    if (!prevState.email && nextProps.user && nextProps.user.email)
      nextState.email = nextProps.user.email;
    if (!prevState.phone && nextProps.phone && nextProps.user.phone)
      nextState.phone = nextProps.user.phone;
    console.log("prevState: ", prevState);
    console.log("user: ", nextProps.user);
    console.log("nextState: ", nextState);
    return nextState;
  }

  render() {
    const value = Object.assign({}, this.state, {
      haveMembership: this.haveMembership,
      haveEmail: this.haveEmail,
      haveConsent: this.haveConsent,
      getPrice: this.getPrice,
      onStep: this.onStep,
      onType: this.onType,
      onToggleConsent: this.onToggleConsent,
      onCheckout: this.onCheckout,
      canCheckout: this.canCheckout,
      setState: this.setState.bind(this)
    });
    return <MembershipContext.Provider value={value}>{this.props.children}</MembershipContext.Provider>
  }
}

export default MembershipProvider;
