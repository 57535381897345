import React from 'react';

const CampContext = React.createContext();

const Steps = {
  START: 0,
  WHERE: 1, CAMP: 1,
  SCAN: 2,
  WHEN: 3,
  EMAIL: 4,
  PHONE: 5,
  FREQUENCY: 6,
  PAYMENT: 7, CHECKOUT: 7,
  THANKS: 8
}

export { CampContext as default, Steps };