import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import MatrixModal from '../modals/MatrixModal'

export default ({show, onClose, onClick, cta}) => {
  const [ example, setExample ] = useState(false);
  const onExample = e => {
    e.preventDefault();
    setExample(true);
  }
  
  return (
    <>
    <MatrixModal show={show && example} onClose={() => setExample(false)} />
    <Modal show={show} onHide={onClose} centered className="csa-modal" id="csa-types-modal">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>

        <div className="csa-info-panel">

          <h2>CAMPSITE <strong>ASSIST</strong> Scans</h2>

          <div className="row justify-content-center py-0 py-md-3">
            <div className="col-12 col-sm-8 col-md-6 pb-2 py-md-3">
              <div className="csa-type-icon"><i className="csa-type-icon-specific"></i></div>
              <h3>Specific Scan</h3>
              <p>Triggers an alert when your scan finds availability matching your exact arrival and departure date</p>
              <ul>
                <li>Scans run frequently (every 3, 13, or 33 minutes)</li>
                <li>Email and SMS Alerts include Reservation Links</li>
                <li>Can be edited prior to receiving an alert</li>
                </ul>
            </div>
            <div className="col-12 col-sm-8 col-md-6 pb-2 py-md-3">
              <div className="csa-type-icon"><i className="csa-type-icon-matrix"></i></div>
              <h3>Matrix Scan</h3>
              <p>Returns a report of all availability found within your date range</p>
              <h6><a href="#" onClick={onExample}>See Example Report</a></h6>
              {
                // [x] JOHN D >> link above reveals MatrixModal 
              }
              <ul>
                <li>Scans run less frequently (every 15, 45, or 90 minutes)</li>
                <li>Email and SMS Alerts link to Report of Availability</li>
                <li>Increases probability of scoring a reservation</li>
              </ul>
            </div>
          </div>

          <div className="text-center pt-3 pt-md-5">
            <a className="btn btn-lg btn-primary" href="#" onClick={onClick || onClose}>{cta || 'Get Started'}</a>
          </div>

        </div>

      </Modal.Body>
    </Modal>
  </>
  )
}