import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default ({show, onClick, onClose, cta}) => (
  <Modal show={show} onHide={onClose} centered className="csa-modal" id="csa-more-modal">
    <Modal.Header closeButton></Modal.Header>
    <Modal.Body>

      <div className="csa-info-panel">

        <h2>What is CAMPSITE <strong>ASSIST?</strong></h2>
        <p>CAMPSITE <strong>ASSIST</strong> is an advanced online tool that finds available campsites when someone cancels a reservation at sold-out campgrounds. <span className="d-inline-block">CAMPSITE <strong>ASSIST</strong></span> will then alert the user (via text and email) when a campsite becomes available. The alert includes a link to the reservation website or availability results paged so you can immediately try and reserve the campsite.</p>

        <div className="text-center pt-5">
          <a className="btn btn-lg btn-primary" href="" onClick={onClick}>{cta || 'Get Started'}</a>
        </div>

      </div>

    </Modal.Body>
  </Modal>
)