import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import UserContext from '../contexts/UserContext.js';
import md5 from 'md5';

const UserIdentity = props => {
  const user = useContext(UserContext);
  const hash = user.email ? md5(user.email.trim().toLowerCase()) : "";
  const src = `https://secure.gravatar.com/avatar/${hash}?s=48&amp;d=mm&amp;r=g`;
  return (
    <div id="csp-user-identity" className="d-flex align-items-center">
    {user && user.isLoggedIn() ? (
      <>
        <Link to="/user">
          <img alt="" src={src} />
        </Link>
        <h6>{user.username || user.email}</h6>
      </>
    ) : (
      <Link to="/create" className="btn btn-primary">Create Account</Link>
    )}
    </div>
  )
}

export default UserIdentity;