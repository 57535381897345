import React, { Component } from 'react';
import {CardElement, injectStripe} from 'react-stripe-elements';
import Spinner from '../components/Spinner.js';
import CampContext, { Steps } from '../contexts/CampContext.js';
import { withRouter } from 'react-router';
import { withUser } from '../providers/UserProvider.js';
import AppStep from '../components/AppStep'

class PaymentStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pending: false
    }
  }

  needPayment = () => {
    const { scanCredits = 0 } = this.props.user;
    const { pack, wantsToPay } = this.context;
    return (pack || !scanCredits || wantsToPay);
  }

  submit = async (e) => {
    this.setState({pending: true});

    let token = null;

    if (this.needPayment()) {
      let stripe_response = await this.props.stripe.createToken({});
      console.log("STRIPE: GOT RESPONSE: ", stripe_response);
      const { error } = stripe_response;
      // error.message
      if (error) {
        this.setState({error: `Error: ${error.message}`, pending: false});
        return;
      }
      token = stripe_response.token;
    }

    const json = await this.context.onCheckout(token);
    if (json.error) {
      this.setState({error: json.error, pending: false});
      return;
    }

    this.setState({
      error: null,
      pending: false
    });

    this.props.user.onJson(json.user);

    this.context.onStep(Steps.THANKS);

/*
    this.props.history.push({
      pathname: "/scans",
      search: "?success=Scan+successfully+created!"
    });
*/
//  alert(`Checkout succeeded with Order ID #${json.order_id}`);
  }
  render() {
    const desktopStyle = {
      base: {
        fontFamily: 'Open Sans, Segoe UI, sans-serif',
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '36px',
        padding: '10px 20px',
        color: '#666',
      },
      invalid: {
        color: '#D62828',
      },
      complete: {
        color: '#7cb24f',
      },
    };
    const mobileStyle = {
      base: {
        fontFamily: 'Open Sans, Segoe UI, sans-serif',
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '24px',
        padding: '8px 12px',
        color: '#666',
      },
      invalid: {
        color: '#D62828',
      },
      complete: {
        color: '#7cb24f',
      },
    };
    const style = window.innerWidth >= 768 ? desktopStyle : mobileStyle;

    const ctx = this.context;
    const { pack, wantsToPay } = ctx;
    const { scanCredits = 0 } = this.props.user;
    const s = num => num == 1 ? '' : 's';

    const cta = wantsToPay || !scanCredits ? 
      `Pay $${ctx.getPrice()} to Start Scan` :
      "Use (1) to Start Scan";

    return (
      <div id="csa-app-checkout">
        <AppStep id="csa-app-payment" idx={Steps.PAYMENT}>
          <div>
{this.needPayment() && (
            <label htmlFor="csa-app-pay">Enter your payment information</label>
)}
            {this.state.error && <p className="checkoutError">{this.state.error}</p>}
{this.needPayment() && (
            <div className="stripe-holder">
              <CardElement style={style} />
            </div>
)}
            <p className="form-text"><i id="csa-app-icon-lock"></i> Your information is secure</p>
          </div>
          <div id="csa-app-last" className="csa-app-step">
            <button className="btn btn-lg btn-primary" onClick={this.submit} disabled={this.state.pending || !ctx.canCheckout()}><Spinner loading={this.state.pending} /> {cta} </button>
            {
              // [x] JOHN D 
              // [x] Button Text Options 
              // [x] Pay ${price} to Start Scan (default: single specific, pack purchase, matrix)
              // [x] Use (1) to Start Scan (IF user has chosen to use a scan from a pack)
            }
          </div>
{pack && (
          <div className="csa-app-payment-note">
            <p><strong>Your purchase includes a {pack} Pack of 3 Minute Scans.</strong><br/>
            The first of these Scans will be used upon purchase.<br/>
            You will have {pack-1} Scan{s(pack-1)} added to your Account.</p>
          </div>
)}
          {
            // [x] JOHN D 
            // IF a User is USING a Scan in a pre-existing Pack 
            // Show the message below
          }
{!wantsToPay && scanCredits > 0 && (
          <div className="csa-app-payment-note">
            <p><strong>Use (1) of your Pre-paid Scans.</strong><br/>
            You will have {scanCredits-1} Scan{s(scanCredits-1)} remaining in your Account.</p>
          </div>
)}
        </AppStep>
      </div>
    )
  }
}
PaymentStep.contextType = CampContext;

const StripePaymentStep = injectStripe(withRouter(withUser(PaymentStep)));

export { StripePaymentStep, PaymentStep };