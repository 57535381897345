import superagent from 'superagent';

const get = url => {
  return superagent.get(url).then(({text}) => JSON.parse(text));
}

const post = (url, data) => {
  return superagent.post(url).type('form').send(data).then(({text}) => JSON.parse(text));
}

const patch = (url, data) => {
  return superagent.patch(url).type('form').send(data).then(({text}) => JSON.parse(text));
}

const destroy = (url, data) => {
  return superagent.delete(url).type('form').send(data).then(({text}) => JSON.parse(text));
}

export { get, post, patch, destroy }