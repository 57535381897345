import React, { useState, useContext } from 'react';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css'
import MembershipProvider from '../providers/MembershipProvider.js';
import MembershipContext, { Steps } from '../contexts/MembershipContext';
import UserProvider from '../providers/UserProvider.js';
import { withUser } from '../providers/UserProvider.js';
import { relative } from 'upath';
import AllSteps from '../pages/MembershipSteps.js';
import LearnMoreModal from '../modals/LearnMoreModal'
import HowItWorksModal from '../modals/HowItWorksModal'
import ScanTypesModal from '../modals/ScanTypesModal'
import FAQModal from '../modals/FAQModal'
import MatrixModal from '../modals/MatrixModal'
import C from 'classnames';

const MembershipProviderWithUser = withUser(MembershipProvider);

const CreateMembershipApp = () => {
  const [ modal, setModal ] = useState(null);
  const ctx = useContext(MembershipContext);
  console.log(ctx)
  const { camp, step, onStep } = ctx;

  const go = selector => {
    if (step <= Steps.MEMBERSHIP && !camp) ctx.setState({focusSearch: false });
    setModal(selector)
  }
  const onClose = e => {
    if (e) e.preventDefault();
    go(null);
  }
  const onGetStarted = e => {
    e.preventDefault(); e.stopPropagation();
    setModal(null);
    if (step <= Steps.MEMBERSHIP && !camp) 
      onStep(Steps.MEMBERSHIP, { focusSearch: true });
  }
  const cta = step > Steps.MEMBERSHIP ? "Got it" : "Get Started";

  return (
    <>
      <div id="campsite-pro-app" className="d-flex flex-column flex-grow-1">
        <div id="csp-intro" className={C(
          { active: step == Steps.START }
        )}>
          { step == Steps.START && <h2>Discover Your Next Campsite <span>with Added Benefits</span></h2> }
          <h1>Upgrade to <span>Campsite PRO</span> Membership</h1>
{step == Steps.START && (
          <>
          <div id="csp-benefits" className="d-flex justify-content-center">
            <div>
              <div className="csp-benefit-icon"><i className="csp-benefit-icon-ad-free"></i></div>
              <h3><span>Ad Free Experience</span>
              on campsitephotos.com*</h3>
            </div>
            <div>
              <div className="csp-benefit-icon"><i className="csp-benefit-icon-discount"></i></div>
              <h3><span>20% Discount</span>
              on <a href="/campsite-assist/">campsite availability alerts</a></h3>
            </div>
            <div>
              <div className="csp-benefit-icon"><i className="csp-benefit-icon-prizes"></i></div>
              <h3><a href="/blog/campsite-pro-member-prize-drawing/campsite-pro-member-prize-drawing/"><span>Pro Member Prize Drawings</span></a>
              held each quarter</h3>
            </div>
            <div>
              <div className="csp-benefit-icon"><i className="csp-benefit-icon-small-biz"></i></div>
              <h3><span>Support a Small Business</span>
              &amp; help fund more photo trips</h3>
            </div>
            <div id="csp-addtl-benefits">
              <p>Additional benefits include access to our picks for best campsites, campground videos and campground maps.</p>
            </div>
          </div>
          <div>
            <button id="csa-intro-btn" className="btn btn-primary" onClick={onGetStarted}>Get Started</button>
            <p>*Excluding embedded afflicate links, promos and offers, as well as direct placement ads.</p>
          </div>
          </>
)}
        </div>

          <div id="csa-app" className={C(
            "init",
            { "hide": step == Steps.START },
            { "active show": step > Steps.START }
          )}>
          <div id="csa-app-canvas">
            <AllSteps />
          </div>
          </div>

      </div>

      { /*
      <LearnMoreModal show={modal == 'what-is-campsite-assist'} onClick={onGetStarted} onClose={onClose} cta={cta} />
      <HowItWorksModal show={modal == 'how-it-works'} onClick={onGetStarted} onClose={onClose} cta={cta} />
      <ScanTypesModal show={modal == 'scan-types'} onClick={onGetStarted} onClose={onClose} cta={cta} />
      <FAQModal show={modal == 'campsite-assist-faqs'} onClose={onClose} />
      <MatrixModal show={modal == 'matrix-report'} onClose={onClose} />
      */ }
    </>
  )
}

export default () => (
  <MembershipProviderWithUser>
    <CreateMembershipApp />
  </MembershipProviderWithUser>
)