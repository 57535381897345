import React, { Component } from 'react';
import LoginPage from '../pages/LoginPage.js';
import ForgotPage from '../pages/ForgotPage.js';
import { Route } from "react-router-dom";

export default function LoginApp(props) {
  return (
    <div id="csp-users" className="d-flex flex-grow-1">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-10 col-lg-6 mx-auto">
            <div id="csp-users-page">
              <Route path="/login" exact component={LoginPage} />
              <Route path={["/forgot", "/forgot-password"]} exact component={ForgotPage} />
            </div>
          </div>
        </div>
      </div>
    </div>
);
}