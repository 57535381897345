import React, { Component } from 'react';
import CampContext, { Steps } from '../contexts/CampContext.js';
import moment from 'moment';
import superagent from 'superagent';
import { withUser } from './UserProvider.js';
import { unique, flatten } from '../lib/Array';
import QS from 'query-string';

class CampProvider extends Component {
  constructor(props) {
    super(props);
    const qs = QS.parse(window.location.search);
    let camp = qs.camp || qs.c;
    this.state = {
      scan_id: null,

      wantsToPay: false,
      startDate: null,
      endDate: null,
//    step: Steps.START,
      step: camp ? Steps.WHERE : Steps.START,
      pack: null,
      frequency: null,
      matrix: false,

      //start test
      /*
      step: Steps.PHONE,
      matrix: true,
      camp: { 
        id: 1,
        name: 'Angel Island SP',
        provider: 'reserveamerica',
        maxNights: 7,
        sites: [],
      }, startDate: moment(), endDate: moment().add(3, 'days'),
      email: 'zeptoon@gmail.com',
      */
      // end test
//    startDate: null, endDate: null
    }
  }

  static getFilters() {
    return {
      canRequire: [
        { id: 'require_hookups', name: 'Hook Ups (Electric)' }, 
        { id: 'require_group', name: 'Group Campsites' }, 
        { id: 'require_handicapped', name: 'ADA / Handicap' }, 
        { id: 'require_equestrian', name: 'Equestrian' }, 
        { id: 'require_picnic', name: 'Picnic Areas / Day Use' }
      ],
      canInclude: [
        { id: 'group', name: 'Group Campsites' }, 
        { id: 'enroute', name: 'Enroute Campsites' }, 
        { id: 'handicapped', name: 'ADA / Handicap' }, 
        { id: 'equestrian', name: 'Equestrian' }, 
        { id: 'picnic', name: 'Picnic Areas / Day Use' }, 
        { id: 'primitive', name: 'Primitive' }, 
        { id: 'hike', name: 'Hike, Bike, Boat In' }
      ]
    }
  }

  static getFilterVars = () => {
    return [].concat.apply([], Object.values(CampProvider.getFilters()))
           .map(filter => filter.id)
  }

  static getFrequencyOptions = () => {
    return [ 
      [  3, 20, "6 months" ],
      [ 13, 15, "4 months" ],
      [ 33, 10, "3 months" ]
    ];
  }

  static getMatrixOptions = () => {
    return [ 
      [ 15, 45, "6 months" ],
      [ 45, 35, "4 months" ],
      [ 90, 25, "3 months" ]
    ];
  }

  static getPackOptions = () => {
    return [
      [10, 160],
      [20, 280],
      [40, 480]
    ]
  }

  getPackOptions = () => CampProvider.getPackOptions()

  onCamp = (camp) => {
//  if (camp && camp.sites) camp.sites.forEach(site => site.selected = true);
    this.setState(ps => {
      const s = { camp }
      const { provider } = camp || {};
/*
      if (!ps.startDate && provider && provider == 'reservecalifornia') {
        s.startDate = moment().add(1, 'days');
        console.log(" -> STARTDATE");
      }
*/
      return s;
    });
  }

  onSite = (site, tf) => {
    if (site) {
      this.setState(state => {
        site.selected = !site.selected; 
        return { camp: state.camp }
      });
    } else {
      this.setState(state => { 
//        const sites = state.camp.sites.map (site => Object.assign({}, site, { selected: tf }));
//        const camp = Object.assign({}, state.camp, { sites });

        state.camp.sites.forEach(site => site.selected = tf); 
        return { camp: state.camp };
      });
    }
  }

  onStep = (step, extraState={}) => {
    const current = this.state.step;
    const next = step;
    if (current >= Steps.WHEN && next < Steps.WHEN) {
      this.setState({ startDate: null, endDate: null });
    }
    this.setState({ ...extraState, step });
  }

  onSpecific = () => this.setState({matrix: false})

  onMatrix = () => this.setState({matrix: true})

  onFrequency = (frequency) => {
    this.setState({frequency, pack: null, wantsToPay: true});
  }

  onPack = (pack) => {
    this.setState({frequency: 3, pack, wantsToPay: true});
  }

  canCheckout = () => {
    return this.haveWhere() &&
           this.haveWhen() &&
           this.haveEmail() && 
//         this.havePhone() &&
           this.haveFrequency();
  }

  onCheckout = (token) => {
    console.log("onCheckout: this.state.camp.sites = ", this.state.camp.sites);
    const selectedSites = (this.state.camp.sites || []).filter(site => site.selected)
    const sites = selectedSites.map(site => site.name);
    console.log("onCheckout: sites = ", sites);
    const vars = {
      assist_id: this.state.camp.id,
      email: this.state.email,
      phone: this.state.phone,
      frequency: this.state.frequency,
      sites: sites.length > 0 ? sites.join("\n") : null,
      token: token ? token.id : null,
      start_date: this.state.startDate.format('YYYY-MM-DD'),
      end_date: this.state.endDate.format('YYYY-MM-DD'),
    }
    if (this.state.matrix) vars['matrix'] = 1;
    if (this.state.pack) vars['pack'] = this.state.pack;

    const forceFilters = unique(flatten(selectedSites.map(site => site.filters || [])));
    CampProvider.getFilterVars().forEach (id => {
      if (forceFilters.includes(id) || !!this.state[id])
        vars[id] = true;
    });
    return superagent.post('/bot-backend/api/checkout').type('form').send(vars).then(({body}) => {
      console.log("onCheckout: got response = ", body);
      const { scan_id } = body;
      this.setState({ scan_id });
      return body;
    });
  }

  haveWhere = () => {
    return (this.state.camp && (
      !this.state.camp.sites ||
      this.state.camp.sites.length == 0 ||
      this.state.camp.sites.some(site => site.selected))
    );
  }

  haveWhen = () => {
    return (this.state.startDate && this.state.endDate);
  }

  hadWhen = () => {
    return false; //(this.state.startDate);
  }
  
  haveEmail = () => {
    return (this.state.email);
  }

  havePhone = () => {
    return (this.state.phone !== undefined);
  }

  haveFrequency = () => {
    return this.state.frequency;
  }

  getPrice = () => {
    const { frequency, pack } = this.state;

    if (pack) 
      return this.getPackOptions().filter(p => p[0] == pack)[0][1];

    if (!frequency) return undefined;

    let frequencies = this.state.matrix ? CampProvider.getMatrixOptions() :
                                            CampProvider.getFrequencyOptions();

    frequencies = this.getMembershipOptions(frequencies)
    return frequencies.filter(f => f[0] == frequency)[0][1];
  }

  onToggle = (what) => {
    this.setState(ps => { 
      if (typeof(ps[what]) == 'undefined' && typeof(this.props.user[what]) !== 'undefined')
        return { [what] : !!!this.props.user[what] }
      else
        return { [what]: !!!ps[what] }
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let nextState = {};
    if (!prevState.email && nextProps.user && nextProps.user.email)
      nextState.email = nextProps.user.email;
    if (!prevState.phone && nextProps.phone && nextProps.user.phone)
      nextState.phone = nextProps.user.phone;
    console.log("prevState: ", prevState);
    console.log("user: ", nextProps.user);
    console.log("nextState: ", nextState);
    return nextState;
  }

  getMembershipOptions = (frequencies) => {
    const subscription = this.props.user.subscription
    if (subscription && subscription.status == "active") {
      let date = new Date(subscription.last_paid_at)
      if (subscription.subscription_type == 'month') {
        const month = date.getMonth()
        date.setMonth(month + 1)
      } else {
        const year = date.getFullYear()
        date.setFullYear(year + 1)
      }

      if (date >= new Date) {
        frequencies.map(frequency => frequency[1] = frequency[1] * (80/100))
      }
    }

    return frequencies;
  }

  render() {
    const value = Object.assign({}, this.state, {
      haveWhere: this.haveWhere,
      haveWhen: this.haveWhen,
      hadWhen: this.hadWhen,
      haveEmail: this.haveEmail,
      havePhone: this.havePhone,
      haveFrequency: this.haveFrequency,
      getPrice: this.getPrice,
      onStep: this.onStep,
      onCamp: this.onCamp,
      onSite: this.onSite,
      onSpecific: this.onSpecific,
      onMatrix: this.onMatrix,
      onFrequency: this.onFrequency,
      onPack: this.onPack,
      onCheckout: this.onCheckout,
      canCheckout: this.canCheckout,
      setState: this.setState.bind(this),
      onToggle: this.onToggle,
      getFrequencyOptions: this.getFrequencyOptions,
      getPackOptions: this.getPackOptions,
      getFilters: this.getFilters,
      getFilterVars: this.getFilterVars,
      getMembershipOptions: this.getMembershipOptions
    }, Object.fromEntries([
      CampProvider.getFilterVars().map(id => ([ id, this.state[id] !== undefined ? this.state[id] : this.props.user[id] ]))
    ]));
    return <CampContext.Provider value={value}>{this.props.children}</CampContext.Provider>
  }
}

export default CampProvider;
