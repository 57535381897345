import React, { Component } from 'react';
import EmailValidator from 'email-validator'
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css'
import CampContext, { Steps } from '../contexts/CampContext.js';
import MaskedInput from 'react-maskedinput'
import noAutoComplete from '../lib/noAutoComplete.js';
import C from 'classnames';
import AppStep from '../components/AppStep'
import PhoneInput from 'react-phone-number-input'

export default class PhoneStep extends Component {
  constructor(props) {
    super(props);
    this.state = { notNow: false, phoneError: false, phone: "", idx: 0 };
    this.phoneRef = null;
  }

  onNotNow = () => {
    if (this.phoneRef && this.phoneRef.input) {
    }

    this.context.setState({phone: null});
    this.context.onStep(Steps.FREQUENCY);
    this.setState(ps => {
      return {phoneError: false, phone: "", notNow: true, idx: ps.idx + 1 }
    });
  }

  onPhone = (withPhone) => {
    console.log("onPhone: withPhone=" + (withPhone ? "T" : "F") + " state=", this.state);
    if (withPhone) {
      const phone = this.state.phone || "";
      console.log(`ONPHONE: ${phone}`);
      const digits = phone.replace(/[^0-9+]+/g, '')
      console.log(`DIGITS: |${digits}| (${digits.length}`)
      const valid = digits.length >= 10;
      if (valid) {
        this.context.setState({phone});
        this.setState({phoneError: false, notNow: false})
        this.context.onStep(Steps.FREQUENCY);
        if (document.activeElement) document.activeElement.blur();
      } else {
        this.context.setState({phone: undefined});
        this.setState({phoneError: true, notNow: false})
      }
    } else this.onNotNow();
  }

  render() {
    const ctx = this.context;
    const { step } = ctx;
    const { emailError, phoneError } = this.state;
    const { international } = this.state;
    const phone = ctx.phone || this.state.phone;
    return (
      <AppStep id="csa-app-user-text" idx={Steps.PHONE}>
        <label htmlFor="csa-app-text">Want us to text you alerts?</label>
        <div className="input-group">

        <PhoneInput
          international withCountryCallingCode 
          defaultCountry="US"
          placeholder="Enter Your Cell Phone Number" 
          value={phone}
          id="csa-app-text" autocomplete="off"
          onChange={value => this.setState({notNow: false, phone: value})}/>

{/*
          {!international ? <MaskedInput 
            id="csa-app-text" 
            key={this.state.idx}
            className={'form-control ' + (phoneError && 'error')} 
            type="text"
            mask="(111) 111-1111" 
            placeholder="Enter Your Cell Phone Number" maxLength={14} 
            onBlur={() => this.onPhone(true)} 
            onKeyUp={e => { 
              console.log("ON KEY UP"); 
              if (e.key == '+') this.setState({international: true});
              else if (e.key == 'Enter') this.onPhone(true);
            }}
            onChange={e => {console.log(e.target.value);this.setState({notNow: false, phone: e.target.value.replace(/[^0-9]+/g, '')})}}
            {...noAutoComplete}
          /> :
           <MaskedInput 
            name="international"
            autoFocus={true}
            id="csa-app-text" 
            key={"international:"+this.state.idx}
            className={'form-control ' + (phoneError && 'error')} 
            defaultValue="+"
            type="text"
            mask="+1 111-111-1111" 
            placeholder="+" maxLength={14} 
            onBlur={() => this.onPhone(true)} 
            onKeyUp={e => { console.log("ON KEY UP"); (e.key == 'Enter') && this.onPhone(true)} }
            onChange={e => {console.log(e.target.value);this.setState({notNow: false, phone: e.target.value.replace(/[^0-9+]+/g, '')})}}
            {...noAutoComplete}
          />}
          */}

          <div className="input-group-append">
            <button className="btn btn-secondary" type="button" onClick={() => this.onPhone(true)}>&gt;</button>
            {
              // JOHN D
              // clicking the phone button is buggy
              // please apply same fixes as email step
            }
          </div>
        </div>
        {(true || !phoneError) && (
          <div>
            <p className="form-text">Get notified instantly! <br />
            <em>(International format - IE: +1 123 456 7890 for US)</em></p>
            <p className="form-text"><a onClick={() => this.onPhone(false) }>Not now</a></p>
          </div>
        )}
        {phoneError && <p className='text-danger'>Please enter a valid Phone Number</p>}
      </AppStep>
    )
  }
}
PhoneStep.contextType = CampContext;

