import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import MaskedInput from 'react-maskedinput'
import 'react-dates-custom-month-year-navigation/initialize';
import { DateRangePicker } from 'react-dates-custom-month-year-navigation';
import moment from 'moment';
import 'react-dates-custom-month-year-navigation/lib/css/_datepicker.css';
import { Modal, Button, ButtonGroup } from 'react-bootstrap';
import { get, post } from '../ajax.js';
import UserContext from '../contexts/UserContext.js';
import BlockUI from 'react-block-ui';
import EmailValidator from 'email-validator'
import noAutoComplete from '../lib/noAutoComplete.js';
import 'react-block-ui/style.css';
import { timingSafeEqual } from 'crypto';
import Error from '../components/Error.js';

export default class ForgotPage extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      blocking: false,
      username: ""
    }
  }

  onChange = (e) => this.setState({[e.target.name]:e.target.value});

  onReset = () => {
    this.context.onReset(this.state.username).then(json => {
      if (json.errors) this.setState({errors: json.errors});
      else {
        this.props.history.push({
          pathname: "/login",
          search: "?success=Please+check+your+e-mail+for+a+login+link."
        });
      }
      return json;
    }).finally(json => {
      this.setState({blocking: false});
    });
    this.setState({blocking: true});
  }

  render() {
    const events = {
      onChange: this.onChange
    };
    const field = (name) => Object.assign({}, {
      id: name,
      name: name,
      value: this.state[name],
      className: "form-control " + (this.state.errors[name] ? "error" : ""),
      ...events
    });

    return (
      <BlockUI tag="div" blocking={this.state.blocking}>
        <h1 className="text-center">Forgot Password</h1>
          <div id="csp-user-login">
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <div className="alert alert-light text-center">Enter your username or email to reset your password.</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label for="username">Username</label>
                  <input type="text" id="username" {...field("username")} placeholder="Username" />
                  <Error msg={this.state.errors.username} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <button onClick={this.onReset} className="btn btn-primary">Reset Password</button>
                </div>
              </div>
            </div>
          </div>
      </BlockUI>
     );
  }
}