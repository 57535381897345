import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import CampContext, { Steps } from '../contexts/CampContext';
import CampProvider from '../providers/CampProvider'
import C from 'classnames';
import moment from 'moment';

export default ({show, onClose, scan={} }) => {
  const campContext = useContext(CampContext);
  const ctx = campContext ? campContext : scan;
  const camp = campContext ? (ctx.camp || {}) : scan;
  const { canRequire, canInclude } = CampProvider.getFilters();
  const required = canRequire.filter(f => ctx[f.id]);
  const included = canInclude.filter(f => ctx[f.id]);
  const selectedSites = (scan && scan.sites ? scan.sites.map(name => ({ name })) : 
                        (camp && camp.sites ? camp.sites.filter(site => site.selected) : []));
  const days = Math.min(Math.max(scan.desired_nights || 7, 1), 7);
  let dates = [];
  const start = moment(scan.desired_date || "2020-11-10")
  let date = moment(start), i = days;
  for (; i-- > 0; date = moment(date).add(1, 'days')) dates.push(date);

  const demoAvailability = {}
  const T = 0.4;
  for (let start_idx = 0; start_idx < days; start_idx++) {
    let sites = 0;
    for (let length = days - start_idx; length > 1; length--) {
      const key = moment(start).add(start_idx, 'days').format('YYYY-MM-DD');
      const r = Math.random();
      sites = Math.max(sites, r < T ? 0 : parseInt(Math.random()*10));
      const sites_array = Array.from(Array(parseInt(sites)).keys())
      if (!demoAvailability[key]) demoAvailability[key] = {};
      demoAvailability[key][length] = sites_array;
    }
  }

  const availability = scan.availability || demoAvailability || {
    "2020-11-11": { "1": [ "A", "B", "C" ] }
  };


  const TD = ({date, len}) => {
    const key = moment(date).format("YYYY-MM-DD");
    const sites = availability[key] && availability[key][len] ? availability[key][len] : [];
    const zIndex = 2000 + (sites.length > 0 ? 10 : 0) + date.diff(start, 'days')
    return (
              <td><div className={C(
                "csa-matrix-report-table-span",
                `csa-mrts-${len}x`,
                { "csa-mrts-active": sites.length > 0 }
              )} style={{ zIndex }}><a target="_blank" rel="noreferer" href={ctx.link}>{sites.length}</a></div></td>
    )
  }

  const TR = ({len}) => dates.length >= len ? (
          <tr id={`csa-matrix-repot-table-row-${len}`}>
{dates.slice(0, dates.length - (len - 1)).map (date => <TD key={moment(date).format("YYYY-MM-DD")} date={date} len={len} />)}
{Array.from(Array((len-1)).keys()).map (item => (
            <td key={item}></td>
))}
          </tr>
  ) : null;

  return (
  <Modal show={show} onHide={onClose} centered className="csa-modal" id="csa-matrix-report-modal">
    <Modal.Header closeButton></Modal.Header>
    <Modal.Body>

      <h5 className="modal-title">Matrix Scan Availability Report</h5>
      {
        // JOHN D 
        // We'll need an example of the Matrix Report -- and below works great for that 
        // Initially we will launch with Matrix Scans being 3-7 nights ONLY 
        // But likely we'll upgrade this to 12-14 nights 
        // Wire up everything below to be dynamic 
        // Link to the Campground with _blank 
        // Include all relevant data 
      }

      <div className="d-flex flex-wrap w-100" id="csa-matrix-report-header">
      <div className="flex-grow-1 px-1">
        <h2><a target="_blank" rel="noreferer" href={ctx.link}>{camp.name || 'Lake Havasu State Park'}</a></h2>
        <p>{typeof(camp.location) !== 'undefined' ? camp.location : 'Lake Havasu, Arizona'} <br />
        {
          // JOHN D if there are campground or campsites / loops -- here
          // wrapped in <span>
        }
{selectedSites ? (
        <strong>
        {selectedSites.map((site,idx) => (
          <span key={site.name}>{site.name}{idx < selectedSites.length - 1 ? ',' : ''}</span>
        ))}
        </strong>
) : (
        <strong><span>Cabins Loop Area,</span> <span>Campground Area</span></strong> 
)
}  
        <br />
        {
          // JOHN D if there are Filters -- here 
          // wrapped in <span>
        }
{required.length > 0 && (
        <>
        <span className="csa-scan-filters">REQUIRED: {required.map(f => <span key={f.name}>{f.name}</span>)}</span> <br />
        </>
)}
{included.length > 0 && (
        <>
        <span className="csa-scan-filters">INCLUDED: {included.map(f => <span key={f.name}>{f.name}</span>)}</span>
        </>
)}
        </p>
      </div>
      <div className="text-right ml-auto">
        <h3>{scan.dates || 'November 10-16, 2020 (7 Nights)'}</h3>
        <p><strong>Updated {(scan.last_checked_at ? moment(scan.last_checked_at) : moment()).format('MMMM DD, YYYY')}</strong><br />
        ({moment().format()})</p>
      </div>
      </div>

      {
        // JOHN D 
        // change the .days-7 class to match how many nights the scan is for
        // .days-6, .days-5, etc. 
        // then ensure you have the correct number of columns in both <thead> and <tbody> 
        // then ensure you are using the correct <tr> ID 
        // #csa-matrix-repot-table-row-1, #csa-matrix-repot-table-row-2, etc 
        // this is how I style the spans in the table 
      }
      <table className={`table table-striped days-${days}`} id="csa-matrix-report-table">
        <thead>
          <tr>
{dates.map (date => (
            <th key={date.unix()} scope="col">{moment(date).format('MMM DD')}</th>
))}
          </tr>
        </thead>
        <tbody>
{Array.from(Array(dates.length).keys()).map ((item,idx) => <TR key={idx} len={idx+1} />)}
          {
            // [x] JOHN D 
            // zIndex counting is important 
            // for the top-most row -- day 7 in our example -- all zIndex can be 2000 -- since they'd all be 1 day spans 
            // beyond that all .csa-matrix-report-table-span will need to know how many days it spans 
            // .csa-mrts-2x, .csa-mrts-3x, etc. 
            // .csa-matrix-report-table-span will also need to know if it's active .csa-mrts-active 
            // for the inactive .csa-matrix-report-table-span zIndex increments from 2000, 2001, 2002, etc
            // for the active .csa-matrix-report-table-span zIndex increments from 2010, 2011, 2012, etc
            // then display the number of available sites we found for each within the <td><div> and <a> if active 
            // <a> should link as deeply to the reservation system as possible 
          }
        </tbody>
      </table>

    </Modal.Body>
  </Modal>
)}