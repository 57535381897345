import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import UserContext from '../contexts/UserContext.js';
import PacksHeader from '../components/PacksHeader';

export default () => {
  const { scanCredits=0 } = useContext(UserContext);

  return (
    <div id="csa-users-header" className="d-flex flex-wrap justify-content-between align-items-center">
      <h1 className="flex-grow-1"><span id="csa-brand">Campsite <span>Assist</span></span></h1>
      <div id="csa-users-header-btns" className="d-flex flex-wrap justify-content-between">
        <div><Link to="/campsite-assist" className="btn btn-primary">New Scan</Link></div>
{!scanCredits && (
        <div><Link to="/packs/buy" className="btn btn-primary">New Pack</Link></div>
)}
      </div>
    </div>
  )
}
