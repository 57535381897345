import React, { Component } from 'react';
import MembershipContext, { Steps } from '../../contexts/MembershipContext.js';
import MembershipAppStep from '../../components/MembershipAppStep'
import C from 'classnames';
import ScanTypesModal from '../../modals/ScanTypesModal'

class PaymentStep extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  toggleSelected = parent => {
    parent.classList.toggle("selected");
    parent.classList.toggle("d-inline-block");
  }

  onType = (element, type, price) => {
    const parent = element.target.parentNode
    this.toggleSelected(parent);
    (parent.classList.contains('selected')) ? this.context.onType(type, price) : this.context.onType(null, price);
    this.context.onStep(Steps.EMAIL);
  }

  onModal = e => {
    e.preventDefault();
    this.setState({modal: true});
  }

  resetStep = () => {
    if (!this.context.subscription_type) {
      return Steps.MEMBERSHIP
    } else {
      return Steps.EMAIL
    }
  }

  render() {
    const ctx = this.context;
    const { step, subscription_type } = ctx;
    const active = step == Steps.MEMBERSHIP;
    const monthly  = active || (!active && subscription_type == "monthly");
    const yearly = active || (!active && subscription_type == "yearly");
    return (
      <>
      <MembershipAppStep id="csa-app-membership" idx={this.resetStep}>
        <label>Select your payment option</label>
        <div className="row justify-content-center">
          <div className={C(
            "col-auto subscription_type",
            { 'd-inline-block': !monthly },
            { selected: subscription_type == "monthly" }
           )}>
            <a href="" className="btn btn-secondary" onClick={(e) => { e.preventDefault(); this.onType(e,'monthly', 4)}}>$4 PER MONTH</a>
            {active && <p className="form-text">Great Value</p>}
          </div>
          <div className={C(
            "col-auto subscription_type",
            { 'd-inline-block': !yearly },
            { selected: subscription_type == "yearly" }
           )} >
            <a href="" className="btn btn-secondary" onClick={(e) => { e.preventDefault(); this.onType(e,'yearly', 40)}}>$40 PER YEAR</a>
            {active && <p className="form-text">Save $8 per year</p>}
          </div>
        </div>
      </MembershipAppStep>
      </>
    )
  }
}
PaymentStep.contextType = MembershipContext;

export default PaymentStep;