import React, { Component, useContext } from 'react';
import superagent from 'superagent';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import CampContext, { Steps } from '../contexts/CampContext.js';
import CampProvider from '../providers/CampProvider';
import { withRouter } from 'react-router-dom';
import QS from 'query-string'
import { unique, flatten } from '../lib/Array';
import C from 'classnames';
import AppStep from '../components/AppStep'

const SEARCHING = 0;
const CHOOSING_SITES = 1;
const FILTERING = 2;

const onToggle = (fullId, state={}, setState) => {
  const ctx = state;
  const { canRequire, canInclude } = CampProvider.getFilters()

  let [ type, id ] = fullId.split("_")
  if (!id) {
    id = type; type = null;
  }

  const key = type == 'require' ? `require_${id}` : id;
  const otherKey = type == 'require' ? id : `require_${id}`
  const checked = ctx[key];
  const otherChecked = ctx[otherKey];
  const requirable = type == 'require' || canRequire.find(filter => filter.id == otherKey)
  const includable = !type || canInclude.find(filter => filter.id == id)

  /* If clicking a require -> set require + include*/
  /* If clicking an include -> unset require */
  const update = {}
  if (type == 'require') {
    if (!checked) {
      /* toggle -> yes */
      if (includable) update[otherKey] = true;
      update[key] = true;
    } else {
      if (includable) update[otherKey] = false;
      update[key] = false;
    }
  } else {
    if (!checked) {
      update[key] = true; /* Include yes */
      if (requirable) update[otherKey] = false; /* Require no */
    } else {
      update[key] = false; /* Include no */
      if (requirable) update[otherKey] = false; /* Require no */
    } 
  }

  console.log("setState: ", update);
  setState(update);
}


class WhereStep extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      q: "",
      noResults: false,
      camps: []
    }

    console.log(props);

    const searchAPI = q => {
      let incoming = new URLSearchParams(window.location.search);
      let params = { q };
      if (incoming.has("id") && incoming.has("c") && 
          q.replace("[", "").replace("]", "") == incoming.get("c")) {
        params.q = incoming.get("id");
      }
      return superagent.get('/bot-backend/api/search').query(params).then(({text}) => text);
    }
    this.searchAPI = searchAPI;
    this.searchAPIDebounced = AwesomeDebouncePromise(searchAPI, 100);

    this.ref = React.createRef();
  }

  componentDidMount() {
    const { props } = this;
    console.log("LOCATION: ", props.location);
    const qs = QS.parse(props.location.search);
    let camp = qs.camp || qs.c;
    if (camp) {
      this.setState({q: camp});
      if (qs.c && camp == qs.c) {
        camp = "[" + qs.c + "]";
      }
      this.searchAPI(camp).then(data => {
        const camps = JSON.parse(data);
        this.setState({onLoad: true, camps: camps, noResults: camps.length == 0}, () => {
          if (camps.length > 0) {
            if (camps[0].type != 'csp_park') this.onCamp(camps[0]);
            else {
              let exact_camp_result = camps.filter(c => c.name == qs.c)[0];
              if (exact_camp_result)
                this.onCamp(exact_camp_result);
            }
          }
        });
      });
    }

    this.focusIfNecessary();
  }

  onChange = async (e) => {
    const q = e.target.value;
    this.context.onCamp(null);
    this.setState({q: q, noResults: false});

    if (q.length >= 3) {
      const result = await this.searchAPIDebounced(q);
      console.log("result: ", result);
      console.log("JSON.parse: ", JSON.parse(result));
      const camps = JSON.parse(result);
      this.setState({camps: camps, noResults: camps.length == 0})
    }
  }

  haveSites = () => {
    const { camp } = this.context;
    return camp && (camp.sites || []).length > 0;
  }
  isSiteSelected = site => {
    return (
      (!site && !this.haveSites()) || 
      (!site && this.haveSites() && this.context.camp.sites.every(site => site.selected)) ||
      (site && this.haveSites() && site.selected)
    );
  }
  isAll = () => {
    const { camp={} } = this.context;
    const { sites=[] } = camp;
    return (!this.haveSites() || sites.every(site => this.isSiteSelected(site)));
  }
  selectedSites = () => {
    return (this.context.camp.sites.filter(site => this.isSiteSelected(site)));
  }
  canMonitorAll = () => {
    const { camp } = this.context;
    return camp && this.haveSites() && camp.type != 'csp_park';
  }
  onCamp = (camp) => {
    const action = camp.sites && camp.sites.length > 0 ? CHOOSING_SITES : FILTERING;
    this.setState({q: camp.name, action });
    this.context.onCamp(camp);
  }
  onSite = (site, tf) => {
    if (this.canMonitorAll() || this.selectedSites().length < 5 || this.isSiteSelected(site))
      this.context.onSite(site, tf);
  }
  componentDidUpdate(prevProps, prevState) {
    if (/*this.state.onLoad &&*/ this.props.onLoad) {
      if (!prevProps.camp ||
          prevProps.camp != this.props.camp ||
          prevProps.site != this.props.site) this.props.onLoad();
//    this.setState({onLoad: false});
    }
  }

  focusIfNecessary = () => {
    const { focusSearch } = this.context;
    if (focusSearch && this.ref && this.ref.current) {
      this.ref.current.focus();
//    alert("yes");
      setTimeout(() => this.ref.current.focus(), 100);
      setTimeout(() => this.ref.current.focus(), 200);
      setTimeout(() => this.ref.current.focus(), 1000);
    }
  }

  onBlur = e => {
    if (!this.context.camp && this.state.camps && this.state.camps.length > 0 && this.state.q == "") {
      /* Hide camps */
      this.setState({camps:[]});
    }
  }

  onToggle = (fullId) => onToggle(fullId, this.context, this.context.setState);

  onAction = action => this.setState({action})

  render() {
    const ctx = this.context;
    const { camp, step } = ctx;
    let { action=SEARCHING } = this.state;

    /* TODO LOGIC HERE */

    console.log(ctx);
    const campground = camp && camp.park ? "campground" : "campsite";
    const Campground = "C" + campground.substr(1);

    let whichmsg = `Which ${Campground} would you like to keep an eye on?`;
    let msg = "";
    if (this.haveSites() && camp.sites.length > 1) {
      whichmsg = `Which ${Campground}s would you like to keep an eye on?`
    }
    if (this.haveSites() && !this.canMonitorAll() && camp.sites.length > 5) {
      if (this.selectedSites().length >= 1) {
        const remaining = (5 - this.selectedSites().length);
        if (remaining > 0) {
          msg = `Please select up to ${remaining} more ${campground}${remaining == 1 ? '' : 's'}`;
        } else {
          msg = `The maximum of 5 ${campground}s have been selected. Please first unselect a ${campground} in order to select a new ${campground}.`
        }
      } else msg = `Please select up to 5 ${campground}s`;
    }
    const chosen = site => this.isSiteSelected(site) && 'chosen';

    const forceFilters = this.haveSites() ? 
       unique(flatten(this.selectedSites().map(site => site.filters))) : []

    const { canRequire, canInclude } = CampProvider.getFilters()
    
    const stringify = filters => filters.filter(f => ctx[f.id]).map (f => f.name).join(", ")
    const stringifyWithAnd = filters => {
      const sf = filters.filter(f => ctx[f.id]).map (f => f.name);
      if (sf.length > 1) 
        return sf.slice(0, sf.length - 1).join(", ") + " and " + sf[sf.length-1];
      return sf.join(", ")
    }
    const required = stringify(canRequire);
    const requiredWithAnd = stringifyWithAnd(canRequire);
    const included = stringify(canInclude);

    console.log(`step=${step} action=${action}`);

    const selectedSites = this.haveSites() ? this.selectedSites() : [];

    return (
      <>
        <AppStep id="csa-app-where" idx={Steps.WHERE}>
          <label htmlFor="csa-app-search">Where do you want to camp?</label>

{(action == CHOOSING_SITES || action == FILTERING) && (
          <div id="csa-app-search-build" className={C(
            { active: step == Steps.WHERE }
          )} onClick={() => this.setState({
            action: SEARCHING
          }) }>
            <span id="csa-app-search-build-name">{camp.name}</span>
{this.haveSites() && selectedSites.length > 0 && (
            <span id="csa-app-search-build-camps">{selectedSites.map((s,i) => [
              <span key={s.name}>{s.name}</span>,
              selectedSites.length > 1 && i < selectedSites.length - 1 && ", "
            ])}</span>
)}

            {required && 
             <span id="csa-app-search-build-require">Require: {required}</span>}

            {included && 
              <span id="csa-app-search-build-include">Include: {included}</span>}

            <span id="csa-app-search-build-location">{camp.location}</span>
          </div>
)}            
{(action == SEARCHING || action == CHOOSING_SITES || action == FILTERING) && (
          <div id="csa-app-search-holder">
{action == SEARCHING && (
<>
            <input ref={this.ref} id="csa-app-search" className="form-control" type="text" value={this.state.q} placeholder="Enter Park or Campground Name" autoComplete="off" autosuggest="off" onChange={this.onChange} onBlur={this.onBlur} />            
{!this.context.camp && this.state.camps && this.state.camps.length > 0 && (
            <div id="csa-app-search-autocomplete">
              <div className="list-group">
              {(this.state.camps || []).map (camp => (
                <>
                <a key={camp.id} onClick={e => this.onCamp(camp)} className="list-group-item list-group-item-action">
                  {/*camp.provider === "recgov" && <><span className="badge">Wilderness Permit</span><br /></>*/}
                  {camp.name} <br />
                  <span>{camp.location}</span>
                </a>
                </>
              ))}
              </div>
            </div>
)}
</>
)}
{step == Steps.WHERE && (
  <>
{!camp && action == SEARCHING && (
              <p className="form-text">Which Park or Campground would you like to keep an eye on?</p>
)}

{(action == SEARCHING || action == CHOOSING_SITES) && camp && (
            <div id="csa-app-search-results">
              {this.haveSites() && <h3>{whichmsg}</h3>}
              <ul>
{this.haveSites() && this.context.camp.sites.map (site => (
                <li key={site.name}><a className={"btn btn-secondary " + chosen(site)} key={site.name} onClick={e => this.onSite(site)}>{site.name}</a></li>
))}
              </ul>
              <div id="csa-app-search-btns" className="d-flex flex-wrap justify-content-center next-step">
{this.haveSites() && (
                <a href="" onClick={e => {
                  e.preventDefault();
                  this.setState({ buttonClicked: true, action: FILTERING });
                }} id="csa-app-search-results-choose-selected-btn" className={C(
                  "btn btn-primary",
                  { "disabled" : !this.isAll() && !this.selectedSites().length }
                )}>Choose Selected</a>
)}
{this.haveSites() && this.canMonitorAll() && (
                <a href="" onClick={e => {
                  e.preventDefault();
                  this.onSite(null, true);
                  this.setState({ buttonClicked: true, action: FILTERING });
                }} id="csa-app-search-results-monitor-all-btn" className="btn btn-primary">Monitor All</a>
)}
              </div>
            </div>
)}
            {!this.state.noResults ?
            <p className="form-text">{msg}</p>
            :
            <p className="form-text text-danger">No Results Found</p>
            }
  </>
)} { /* !step==where */ }
          </div>
)}
{camp && step == Steps.WHERE && action == FILTERING && (
          <div id="csa-app-search-filter" className="csa-app-step">
          <label htmlFor="csa-app-filters" className="mb-0">Scan Filters</label>
          <p className="form-text"><em>If available and/or applicable</em></p>
          <div id="csa-app-filters">

{/* REMOVE REQUIRE FILTERS FOR NOW 2/23/22 
            <label htmlFor="csa-app-required-filters"><small>Require in scan</small></label>
            <div className="csa-app-filter-container mx-auto mb-4">
{
  // -- REQUIRE FILTERS -- 
}
{canRequire.map (filter => (
              <div key={filter.id} className="custom-control custom-checkbox d-inline-flex mb-2 px-4">
              <input id={filter.id} type="checkbox" className="custom-control-input" checked={ctx[filter.id] ? true : false} onChange={() => this.onToggle(filter.id)} />
              <label className="custom-control-label" htmlFor={filter.id}>{filter.name}</label>
              </div>
))}
{requiredWithAnd && (
              <div className="alert alert-danger csa-alert" role="alert">
                Please be sure that {camp.name} offers sites with {requiredWithAnd}, otherwise your scan will not produce any alerts.
              </div>
)}

            </div>
*/ }

            <label htmlFor="csa-app-required-filters"><small>Include in scan</small></label>
            <div className="csa-app-filter-container mx-auto mb-2">
{/* -- INCLUDE FILTERS -- */}
{canInclude.map (filter => (
              <div key={filter.id} className="custom-control custom-checkbox d-inline-flex mb-2 px-4">
              <input id={filter.id} disabled={forceFilters.includes(filter.id)} type="checkbox" className="custom-control-input" checked={forceFilters.includes(filter.id) || ctx[filter.id] ? true : false} onChange={() => this.onToggle(filter.id)} />
              <label className="custom-control-label" htmlFor={filter.id}>{filter.name}</label>
              </div>
))}

            </div>
          </div>

        </div>
)}
{step == Steps.WHERE && this.context.camp && action == FILTERING && (
            <div>
              <a onClick={() => ctx.onStep(Steps.SCAN)} className="btn btn-primary btn-continue mx-auto">Continue</a>
            </div>
)}
        </AppStep>
      </>
    )
  }
}
WhereStep.contextType = CampContext;

export { onToggle };

export default withRouter(props => {
  const ctx = useContext(CampContext);
  const { camp, site } = ctx;
  return <WhereStep key={ctx.focusSearch ? "focus" : ""} camp={camp} site={site} {...props} />
})
