import React, { Component } from 'react';
import WhereStep from '../steps/WhereStep.js';
import ScanStep from '../steps/ScanStep.js';
import WhenStep from '../steps/WhenStep.js';
import EmailStep from '../steps/EmailStep.js';
import PhoneStep from '../steps/PhoneStep.js';
import FrequencyStep from '../steps/FrequencyStep.js';
import { StripePaymentStep } from '../steps/PaymentStep.js';
import ThanksStep from '../steps/ThanksStep.js';
import CampContext, { Steps } from '../contexts/CampContext.js';

const AltSlideDown = ({closed, children}) => !closed && children

export default class extends Component {
  static contextType = CampContext;

  constructor() {
    super();
  }

  scrollTo = element => {
    const { innerWidth } = window;
    if (innerWidth < 768) return;

    const u = new URLSearchParams(window.location.search.substr(1))
    const ctx = this.context;
    if (u.has("c") && !ctx.haveWhen()) return;

    console.log("scrollTo: c=", u.get("c"), " haveWhen=", ctx.haveWhen());

    if (element) (element.current || element).scrollIntoView({ behavior: "smooth", block: "end" });
  }

  componentDidMount() {
    const ctx = this.context;
//  alert("mounted with when = " + (ctx.haveWhen() ? " present " : "not present")); 
  }

  componentDidUpdate(prevProps, prevState) {
//  this.scrollTo(document.getElementById("csa-more-info")); DISABLESCROLL
  }

  render() {
    const ctx = this.context;
    const { step } = ctx;
    // [x] JOHN D //FUTURE
    // we will need to define scrolling now that we removed it 
    // with the extra padding on the .active steps 
    // going from one to the next can get a little odd 
    return (
      <>
        {step >= Steps.WHERE && step < Steps.THANKS && (
          <>
            {step >= Steps.WHERE && <WhereStep />}
            <AltSlideDown closed={step < Steps.SCAN}><ScanStep /></AltSlideDown>
            <AltSlideDown closed={step < Steps.WHEN}><WhenStep /></AltSlideDown>
            <div id="csa-app-user">
              <AltSlideDown closed={step < Steps.EMAIL}><EmailStep /></AltSlideDown>
              <AltSlideDown closed={step < Steps.PHONE}><PhoneStep /></AltSlideDown>
            </div>
            <AltSlideDown closed={step < Steps.FREQUENCY}><FrequencyStep /></AltSlideDown>
            <AltSlideDown closed={step < Steps.PAYMENT}><StripePaymentStep /></AltSlideDown>
          </>
        )}
        {step == Steps.THANKS && <ThanksStep />}
      </>
    )
  }
};

/*
export default () => {
  const ctx = useContext(CampContext);
  const step = ctx.haveFrequency() ? 'payment' : 
               ctx.havePhone() ? 'frequency' : 
               (ctx.haveEmail() || (!ctx.hadWhen() && !ctx.haveWhen())) ? 'phone' : 
               (ctx.hadWhen() || ctx.haveWhen()) ? 'email' : 
               ctx.haveWhere() ? 'when' : 'where';

  return (
    <AllSteps step={step} />
  )
}
*/