import React, { useRef, useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import C from 'classnames';

export default ({show, onClose}) => {
  const ref = useRef(null);
  const [ scrolling, setScrolling ] = useState(false);
  const onScroll = () => {
    if (!ref || !ref.current) return;

    setScrolling(ref.current.scrollTop > 30);
  }
  useEffect(() => {
    if (!ref || !ref.current) return undefined;

    ref.current.addEventListener('scroll', onScroll);
    return () => ref && ref.current && ref.current.removeEventListener('scroll', onScroll)
  }, [ref && ref.current, show]);

  return (
    <Modal show={show} onHide={onClose} centered className="csa-modal" id="csa-faq-modal">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>

        <div id="campsite-assist-faqs">
          <div className="csa-info-panel">

            <h2 className={C({ scrolling })}>FAQs</h2>
            
            <div id="csa-faq-scroll" ref={ref}>

              <div className="csa-faq">
                <h3>What is CAMPSITE <strong>ASSIST</strong> and how does it work?</h3>
                <p>CAMPSITE <strong>ASSIST</strong> provides an alert when a campsite becomes available at a specific campground. It is a specialized (Internet) program that continuously scans (24/7) for cancelled campsite reservations at sold-out campgrounds. Once a campsite becomes available, CAMPSITE <strong>ASSIST</strong> then instantly sends you a notification (via text and email) with a link to the reservation website where you can try and reserve the campsite.</p>
                <p>CAMPSITE <strong>ASSIST</strong> starts scanning for available campsites within a few minutes after you've paid for your scan &ndash; IF the reservation window is open for the dates you want to stay. Your scan will continue to run up until your arrival date or the end of the time period of your scan.</p>
              </div>

              <div className="csa-faq">
                <h3>Why hasn't my Campsite Assist scan started?</h3>
                <p>If your scan has not started, it's usually because you have entered an arrival date outside of the reservation window (too far in the future). Most campgrounds / reservation systems open up reservations 6 months in advance of your arrival date. For example, if you set up a scan for an arrival date 8 months in the future, your scan won't start until the reservation window opens in 2 months (6 months prior to your arrival date).</p>
              </div>

              <div className="csa-faq">
                <h3>How do I sign up for a CAMPSITE <strong>ASSIST</strong> alert?</h3>
                <p>Signing up for a CAMPSITE <strong>ASSIST</strong> scan is easy. We offer two types of scans &ndash; a "Specific Scan" and a "Matrix Scan". Simply choose the type of scan you want, campground, campsite types and make your payment to get started. </p>
              </div>

              <div className="csa-faq">
                <h3>What is the difference between the Specific Scan and Matrix Scan?</h3>
                <p>With the Specific Scan you choose an exact arrival date and length of stay for one campground. You'll also have the option to select the scanning frequency (every 3 minutes, 13 minutes or 33 minutes). An alert will be sent to you (text &amp; email) if a campsite becomes available for your specific arrival date and length of stay.</p>
                <p>The Matrix Scan allows you to scan for availability at one campground within a set date range (up to 7 days). You'll also have the option to select the scanning frequency (every 15, 45, or 90 minutes). You will get an alert (text &amp; email) if a campsite becomes available for any duration during the date range you selected.</p>
              </div>

              <div className="csa-faq">
                <h3>How do scan packs work?</h3>
                <p>You can purchase a pack of scans (10, 20 and 40) and save up to 40% off individual specific scans. <strong><em>Scan packs are not available for matrix scans.</em></strong> This is a great option if you're planning on doing a lot of camping over the next 18 months.</p>
              </div>

              <div className="csa-faq">
                <h3>How do I use a scan from my scan pack?</h3>
                <ol>
                  <li>Choose a campground.</li>
                  <li>Select 'Specific Scan'.</li>
                  <li>Choose your dates.</li>
                  <li>Enter the email and phone number you'd like to receive alerts.</li>
                  <li>Then you'll see a box / link above the pricing (see example below) where you can select to use one of your scans:<br />
                  <img className="img-fluid border my-3 p-1" src="//d2g85s3tfaxbly.cloudfront.net/imgs/CSA-FAQ-Scan-Packs-1.png" alt="Option to Use a Scan from your Pack" />
                  </li>
                  <li>The preceding screen will allow you to confirm you want to use 1 of your scans from your pack:<br />
                  <img className="img-fluid border my-3 p-1" src="//d2g85s3tfaxbly.cloudfront.net/imgs/CSA-FAQ-Scan-Packs-2.png" alt="Confirm the Use of a Scan from your Pack" />
                  </li>
                </ol>
              </div>

              <div className="csa-faq">
                <h3>Is the CAMPSITE <strong>ASSIST</strong> scan fee a one-time charge or per scan?</h3>
                <p>The scanning fee is a one-time charge. Depending on the scanning fee you selected, it will include continuous scans every 3, 13 or 33 minutes for specific scans, or every 15, 45, or 90 minutes for Matrix scans. Your scans will continue to run up until your arrival date or the time period associated with the scanning level, whichever comes first.</p>
              </div>

              <div className="csa-faq">
                <h3>When will I get a campsite availability alert?</h3>
                <p>You will receive an alert if a campsite becomes available for the dates you entered for your specific scan or in the date range of your matrix scan. You will receive additional availability alerts if more campsites become available.</p>
              </div>

              <div className="csa-faq">
                <h3>How quickly will I be notified when CAMPSITE <strong>ASSIST</strong> finds a site that becomes available?</h3>
                <p>Immediately. You will receive a text and email alerting you that a campsite is available at the campground you chose during the dates you provided. It's up to you to click on the link (in the alert) to the reservation website to try and reserve the campsite before someone else beats you to it.</p>
              </div>

              <div className="csa-faq">
                <h3>What do I do once I receive a notification that a campsite has become available?</h3>
                <p>Click on the link in your text or email right away! It will route you to the reservation website for the campground you chose. Once there &ndash; type in the name of your campground (if not already populated) and the arrival/departure dates. A campsite (s) will show as available if someone did not already reserve it. As you're probably aware, competition for campsites is intense and you'll need to act quickly.</p>
              </div>

              <div className="csa-faq">
                <h3>How can I check on the status or stop my scans?</h3>
                <p>Click on the link in your original confirmation email. This will take you to your dashboard where you view the status or stop your scans.</p>
              </div>
              
              <div className="csa-faq">
                <h3>Can I edit/change the dates of my scans?</h3>
                <p>From your dashboard, you can change the dates of arrival/departure at any up until you get an availability alert. You cannot change the dates once you receive an availability alert.</p>
              </div>

              <div className="csa-faq">
                <h3>What type of campsites are included in my scan order?</h3>
                <p>Your scan order will include all single-family campsites (standard or with hookups) throughout the campground. By default, your scans will exclude group, enroute, ADA, equestrian, picnic areas, primitive, walk-in, boat-in and hike-in campsites. However, you can click on the checkbox for any or all of these if you would like to include in your scan order.</p>
              </div>

              <div className="csa-faq">
                <h3>Can I choose specific types of campsites?</h3>
                <p>Yes, you have the ability to include or exclude group, enroute, ADA, equestrian, picnic areas, primitive, walk-in, boat-in and hike-in campsites. However, because of the way the reservation systems are set up we can't scan for specific campsite numbers within a campground.</p>
                <p>You can click ‘monitor all' if you want to get an alert if any type of campsite becomes available on your dates. You may find that you get a # of alerts just for a particular campsite that isn't popular so narrowing your selection to just a few you're really interested may be better.</p>
              </div>

              <div className="csa-faq">
                <h3>Does CampsitePhotos.com / CAMPSITE <strong>ASSIST</strong> make reservations for customers and/or resell?</h3>
                <p>No. We do not book campsite (or other) reservations for our customers or anyone else. We also never book and resell campsite (or other) reservations to our customers or anyone else. CAMPSITE <strong>ASSIST</strong> is a service we provide that monitors when a campsite becomes available and then notifies the customer of the availability. Once notified, it's up to the customer to try and make a reservation through the campground or park's reservation system.</p>
              </div>

              <div className="csa-faq">
                <h3>Does the link you provide in the notification send me to the campground and/or campsite I requested?</h3>
                <p>The link will either route you to the specific campground or the main page of reservation agency's website. Some reservation agency sites do not have specific permalinks/URLs for the campgrounds and so we can only route you to the main page. Other reservation agency sites do have specific campground permalinks/URLs and for those we can route you straight to the campground page. From there, it's up to you to try and reserve the campsite.</p>
              </div>

              <div className="csa-faq">
                <h3>If I order a scan for a multiple day stay, say July 10&ndash;15, will I get an alert if a campsite becomes available for just 1 or 2 of the days within my window?</h3>
                <p>The matrix scan will alert you for any length of availability within the date range you selected. The specific scan will only send you an alert if a campsite becomes available for the specific arrival/departure dates you selected.</p>
              </div>

              <div className="csa-faq">
                <h3>After I get an alert for a cancelled campsite and click on the link to reserve, all campsites are reserved. What happened?</h3>
                <p>Campsites are in very high demand, particularly for popular campgrounds and during peak times. Other campers may have received an alert for a cancelled campsite or someone was randomly checking for campsites and reserved the site before you could. Remember &ndash; act immediately and click on the reservation link once you receive a campsite alert.</p>
              </div>

              <div className="csa-faq">
                <h3>How do I improve my chances for getting an alert for a cancelled campsite?</h3>
                <p>There are a few things you can do to increase your chances for getting a cancelled campsite alert.</p>
                <ol>
                  <li>Choose a quicker scanning frequency (i.e. 3 minutes) &ndash; you'll have a 10 or 30 minute jump on someone that chooses scans of 13 or 33 minutes.</li>
                  <li>Try shortening the length of your stay (i.e. 2 or 3 nights vs. 6 or 7 nights).</li>
                  <li>Enter an arrival date well in advance (4&ndash;6 months).</li>
                  <li>Don't wait! Once you get your alert &ndash; click on the link immediately and try to reserve the campsite.</li>
                </ol>
              </div>

              <div className="csa-faq">
                <h3>Will I get a refund if I do not receive an alert for a cancelled campsite?</h3>
                <p>No, all payments for cancelled campsite scans are final whether you receive an alert or not. We have no way of knowing whether someone will cancel a campsite at a particular campground for the dates you selected. However, life happens and people do cancel campsites all the time. Entering an arrival date well in the future will improve your chances of getting a campsite alert.</p>
              </div>

              <div className="csa-faq">
                <h3>What campgrounds do you scan?</h3>
                <p>CAMPSITE <strong>ASSIST</strong> is scanning for cancelled campsites on ReserveCalifornia.com, Recreation.gov (all National Parks, USFS campgrounds, federal campgrounds and some BLM campgrounds), ReserveAmerica.com (various State Parks), Florida State Parks and Minnesota State Parks.</p>
              </div>

              <div className="csa-faq">
                <h3>How do I know that CAMPSITE <strong>ASSIST</strong> is sending me legitimate notifications for campsites that become available?</h3>
                <p>We guarantee that IF a campsite becomes available at the campground and on the dates you selected &ndash; you will receive an alert. Our CAMPSITE <strong>ASSIST</strong> program is state-of-the-art. In addition, we constantly monitor and update the system to make sure it is operating at peak performance levels. Occasionally the reservation agencies will make changes to their system that may cause a hiccup, but we are continually monitoring the reservation systems to make sure everything works perfectly.</p>
              </div>

              <div className="csa-faq">
                <h3>Is the fee charged by CAMPSITE <strong>ASSIST</strong> part of my camping reservation fee?</h3>
                <p>No. The CAMPSITE <strong>ASSIST</strong> scan fee is not part of your camping reservation fee. You will pay an additional fee to the reservation agency for your camping reservation.</p>
              </div>

              <div className="csa-faq">
                <h3>Is CAMPSITE <strong>ASSIST</strong> reserving and reselling campsites that become available?</h3>
                <p>No. CAMPSITE <strong>ASSIST</strong> scans for cancelled campsites and sends alerts only to people who have paid for the service. We do not reserve or resell cancelled campsites identified from our scan or otherwise.</p>
              </div>

              <div className="csa-faq">
                <h3>Will you book the campsite for me?</h3>
                <p>No, CAMPSITE <strong>ASSIST</strong> only alerts you when a campsite becomes available (usually through a cancellation). Once you receive the alert, you'll need to click on the link (included in your text and/or email notification we send you) which will route you to the reservation website so you can try and reserve the campsite.</p>
              </div>

              <div className="csa-faq">
                <h3>CAMPSITE <strong>ASSIST</strong> Customer Service/Support</h3>
                <p>For customer service, technical support or suggestions to improve CAMPSITE <strong>ASSIST</strong> please email us at <a href="mailto:campranger@campsitephotos.com">campranger@campsitephotos.com</a>.</p>
              </div>

            </div>

          </div>
        </div>

      </Modal.Body>
    </Modal>
  )
}