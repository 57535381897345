import React, { Component } from 'react';
import CampContext, { Steps } from '../contexts/CampContext.js';
import AppStep from '../components/AppStep'
import C from 'classnames';
import { withRouter } from 'react-router';

class ThanksStep extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.gtag('config', 'AW-676853644');
    window.gtag('event', 'conversion', {'send_to': 'AW-676853644/KYBrCNzxv6UDEIzv38IC'});
  }

  onSee = e => {
    const { scan_id } = this.context;
    e.preventDefault();
    this.props.history.push({
      pathname: "/scans",
      search: `?success=Scan+successfully+created!#s${scan_id}`
    });
  }

  onAccount = e => {
    e.preventDefault();
    this.props.history.push({ pathname: "/user" });
  }

  onNew = e => {
    e.preventDefault();
    window.location.reload();
  }

  render() {
    const { step, scan } = this.context;
    return (
      <AppStep id="csa-app-thanks" idx={Steps.THANKS}>
        <h2>Thank you for your order!</h2>
        <p><a onClick={this.onSee}>Your Scan</a> is initializing and will begin shortly.</p>
        <p>In the meantime, we recommend <a onClick={this.onAccount}>setting up your account</a> if you have not already.</p>
        <div className="pt-5 pb-3 d-flex flex-wrap justify-content-center">
          <a onClick={this.onAccount} className="btn btn-primary">Go to My Account</a>
          <a onClick={this.onSee} className="btn btn-primary">See My Scan</a>
          <a onClick={this.onNew} className="btn btn-primary">Create Another Scan</a>
        </div>
      </AppStep>
    )
  }
}
ThanksStep.contextType = CampContext;

export default withRouter(ThanksStep);