import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import MaskedInput from 'react-maskedinput'
import 'react-dates-custom-month-year-navigation/initialize';
import { DateRangePicker } from 'react-dates-custom-month-year-navigation';
import moment from 'moment';
import 'react-dates-custom-month-year-navigation/lib/css/_datepicker.css';
import { Modal, Button, ButtonGroup } from 'react-bootstrap';
import { get, post } from '../ajax.js';
import UserContext from '../contexts/UserContext.js';
import BlockUI from 'react-block-ui';
import EmailValidator from 'email-validator'
import noAutoComplete from '../lib/noAutoComplete.js';
import 'react-block-ui/style.css';
import { timingSafeEqual } from 'crypto';
import Error from '../components/Error.js';

export default class LoginPage extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      username: "",
      password: "",
      blocking: false
    }
  }

  onLogin = () => {
    this.setState({blocking: true});
    this.context.onLogin(this.state.username, this.state.password).then(json => {
      if (json.errors) this.setState({errors: json.errors});
      else if (json.username) {
        if (this.props.history.location.previous)
          this.props.history.push(this.props.history.location.previous);
        else
          this.props.history.push({
            pathname: "/user",
            search: "?success=You've+successfully+logged+in"
          });
      }
      return json;
    }).finally(json => {
      this.setState({blocking: false});
    });
  }

  onChange = (e) => this.setState({[e.target.name]:e.target.value});

  componentDidMount() {
    let params = new URLSearchParams(this.props.location.search);
    if (params.has("success")) {
      this.setState({ success: params.get("success") });
    } else if (params.has("key") && this.context.isLoggedIn()) {
      this.props.history.push({
        pathname: "/user",
        search: "?success="+escape("You've been automaticaly logged in. Please update your password.")
      });
    }
  }

  render() {
    const events = {
      onChange: this.onChange
    };
    const field = (name) => Object.assign({}, {
      id: name,
      name: name,
      value: this.state[name],
      className: "form-control " + (this.state.errors[name] ? "error" : ""),
      ...events
    });
    return (
      <BlockUI tag="div" blocking={this.state.blocking}>
        <h1 className="text-center">Login to CampsitePhotos.com</h1>
        { this.state.success && <div className="alert alert-info">{this.state.success}</div> }

        <div id="csp-user-login">
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label for="username">Username</label>
                <input type="text" id="username" {...field("username")} placeholder="Username"  />
                <Error msg={this.state.errors.username} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label for="pwd">Password</label>
                <input type="password" id="pwd" {...field("password")} placeholder="Password" />
                <Error msg={this.state.errors.password} />
                <br />
                <Link to="/forgot">Forgot password?</Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <button className="btn btn-primary" onClick={this.onLogin}>Login</button>
              </div>
            </div>
          </div>
        </div>
      </BlockUI>
     );
  }
}