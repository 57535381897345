import React, { Component } from 'react';
import MembershipStep from '../steps/membership_steps/MembershipStep.js';
import EmailStep from '../steps/membership_steps/EmailStep.js';
import { StripePaymentStep } from '../steps/membership_steps/PaymentStep.js';
import ThanksStep from '../steps/membership_steps/ThanksStep.js';
import MembershipContext, { Steps } from '../contexts/MembershipContext.js';

const AltSlideDown = ({closed, children}) => !closed && children

export default class extends Component {
  static contextType = MembershipContext;

  constructor() {
    super();
  }

  scrollTo = element => {
    const { innerWidth } = window;
    if (innerWidth < 768) return;

    const u = new URLSearchParams(window.location.search.substr(1))
    const ctx = this.context;
    if (u.has("c") && !ctx.haveWhen()) return;

    console.log("scrollTo: c=", u.get("c"), " haveWhen=", ctx.haveWhen());

    if (element) (element.current || element).scrollIntoView({ behavior: "smooth", block: "end" });
  }

  componentDidMount() {
    const ctx = this.context;
  }

  componentDidUpdate(prevProps, prevState) {
  }

  render() {
    const ctx = this.context;

    const { step } = ctx;
    return (
      <>
        {step >= Steps.MEMBERSHIP && step < Steps.THANKS && (
          <>
            {step >= Steps.MEMBERSHIP && <MembershipStep />}
            <div id="csa-app-user">
              <AltSlideDown closed={step < Steps.EMAIL}><EmailStep /></AltSlideDown>
            </div>
            <AltSlideDown closed={step < Steps.PAYMENT}><StripePaymentStep /></AltSlideDown>
          </>
        )}
        {step == Steps.THANKS && <ThanksStep />}
      </>
    )
  }
};
