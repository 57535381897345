import React, { Component } from 'react';
import styled, { keyframes }  from 'styled-components';

export default class Spinner extends Component {
  render() {
    return <Loader loading={this.props.loading}><div></div><div></div><div></div><div></div></Loader>
  }
}

const Keyframes = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Loader = styled.div`
  display: ${({loading}) => loading ? 'inline-block' : 'none'};
  position: relative;
  top: 4px;
  margin-top: -2px;
  vertical-align: baseline;
  width: 26px;
  height: 26px;
  margin-right: 5px;

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0px;
    border: 3px solid #fff;
    border-radius: 50%;
    animation: ${Keyframes} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }

  & div:nth-child(1) {
    animation-delay: -0.45s;
  }
  & div:nth-child(2) {
    animation-delay: -0.3s;
  }
  & div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;