import React, { useState, useContext } from 'react';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css'
import CampProvider from '../providers/CampProvider.js';
import CampContext, { Steps } from '../contexts/CampContext';
import UserProvider from '../providers/UserProvider.js';
import { withUser } from '../providers/UserProvider.js';
import { relative } from 'upath';
import AllSteps from '../pages/Steps.js';
import LearnMoreModal from '../modals/LearnMoreModal'
import HowItWorksModal from '../modals/HowItWorksModal'
import ScanTypesModal from '../modals/ScanTypesModal'
import FAQModal from '../modals/FAQModal'
import MatrixModal from '../modals/MatrixModal'
import C from 'classnames';

const CampProviderWithUser = withUser(CampProvider);

const CreateScanApp = () => {
  const [ modal, setModal ] = useState(null);
  const ctx = useContext(CampContext);
  const { camp, step, onStep } = ctx;

  const go = selector => {
    if (step <= Steps.WHERE && !camp) ctx.setState({focusSearch: false });
    setModal(selector)
  }
  const onClose = e => {
    if (e) e.preventDefault();
    go(null);
  }
  const onGetStarted = e => {
    e.preventDefault(); e.stopPropagation();
    setModal(null);
    if (step <= Steps.WHERE && !camp) 
      onStep(Steps.WHERE, { focusSearch: true });
  }
  const cta = step > Steps.WHERE ? "Got it" : "Get Started";

  return (
    <>
      <div id="campsite-assist-app" className="d-flex flex-column flex-grow-1">
        <div id="csa-intro" className={C(
          { active: step == Steps.START }
        )}>
          <h1>Campsite <span>Assist</span></h1>
{step == Steps.START && (
          <>
            <h2>Get text &amp; email alerts when a sold out campground has availability.</h2>
            <p>Your alert will include a link so you can try and reserve the available campsite.</p>
            <button id="csa-intro-btn" className="btn btn-primary" onClick={onGetStarted}>Get Started</button>
            <div id="csa-intro-bullets">
              <ul>
                <li>Scan for availability for specific arrival/departure dates, or within a date range.</li>
                <li><span>Save up to 40%</span> when you purchase a pack of specific scans.</li>
                <li>Click a button below to learn more about CAMPSITE <strong>ASSIST</strong>.</li>
              </ul>
            </div>
            <p><a class="text-white" href="/campsite-pro">Upgrade to Campsite PRO Membership</a> and get 20% of availability alerts and enjoy an ad free experience!</p>
          </>
)}
        </div>

          <div id="csa-app" className={C(
            "init",
            { "hide": step == Steps.START },
            { "active show": step > Steps.START }
          )}>
          <div id="csa-app-canvas">
            <AllSteps />
          </div>
          </div>

        <div id="csa-more-info" className="mt-auto mb-4">
          <button id="csa-more-btn" className="btn btn-light" onClick={() => go('what-is-campsite-assist')}>Learn More</button>
          <button id="csa-how-btn" className="btn btn-light" onClick={() => go('how-it-works')}>How It Works</button>
          <button id="csa-types-btn" className="btn btn-light" onClick={() => go('scan-types')}>Scan Types</button>
          <button id="csa-faqs-btn" className="btn btn-light" onClick={() => go('campsite-assist-faqs')}>FAQs</button>
          {
            // JOHN D 
            // once we have the Matrix Report wired up and the example linked to via the Scan Types modal 
            // remove the button below
            //<button className="btn btn-light" onClick={() => go('matrix-report')}>Matrix Report</button>
          }
        </div>

      </div>

      <LearnMoreModal show={modal == 'what-is-campsite-assist'} onClick={onGetStarted} onClose={onClose} cta={cta} />
      <HowItWorksModal show={modal == 'how-it-works'} onClick={onGetStarted} onClose={onClose} cta={cta} />
      <ScanTypesModal show={modal == 'scan-types'} onClick={onGetStarted} onClose={onClose} cta={cta} />
      <FAQModal show={modal == 'campsite-assist-faqs'} onClose={onClose} />
      <MatrixModal show={modal == 'matrix-report'} onClose={onClose} />
    </>
  )
}

export default () => (
  <CampProviderWithUser>
    <CreateScanApp />
  </CampProviderWithUser>
)