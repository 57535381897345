import React, { Component, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import MaskedInput from 'react-maskedinput'
import 'react-dates-custom-month-year-navigation/initialize';
import { isInclusivelyBeforeDay, DateRangePicker } from 'react-dates-custom-month-year-navigation';
import CampProvider from '../providers/CampProvider';
import moment from 'moment';
import 'react-dates-custom-month-year-navigation/lib/css/_datepicker.css';
import { Modal, Button, ButtonGroup } from 'react-bootstrap';
import { get, post } from '../ajax.js';
import UserContext from '../contexts/UserContext.js';
import BlockUI from 'react-block-ui';
import EmailValidator from 'email-validator'
import noAutoComplete from '../lib/noAutoComplete.js';
import 'react-block-ui/style.css';
import { START_DATE, END_DATE } from 'react-dates-custom-month-year-navigation/constants';
import { onToggle } from '../steps/WhereStep';
import { unique, flatten } from '../lib/Array';
import C from 'classnames';
import MatrixModal from '../modals/MatrixModal'
import PhoneInput from 'react-phone-number-input'

const stringify = filters => filters.map (f => f.name).join(", ")

class EditScan extends Component {
  constructor(props) {
    super(props);
    const { email, phone, arrival, departure } = props.scan;
    this.state = { 
      focusedInput: null,
      errors: {},
      email,
      phone,
      arrival: moment(arrival),
      departure: moment(departure),
    }
    CampProvider.getFilterVars().forEach(id => this.state[id] = props.scan[id]);

    this.ref = React.createRef();
  }

  componentDidMount() {
    if (this.ref && this.ref.current) this.ref.current.scrollIntoView({ behavior: 'smooth' });
  }

  onUpdate = () => {
    const errors = {};
    if (!EmailValidator.validate(this.state.email)) {
      errors.email = "Please enter a valid Email";
    }
    if (this.state.phone && (this.state.phone || "").replace(/[^0-9]+/g, '').length < 10) {
      errors.phone = "Please enter a valid Phone";
    }
    if (Object.keys(errors).length == 0) {
      const forceFilters = this.forceFilters();
      const fields = {
        email: this.state.email,
        phone: this.state.phone,
      }
      if (this.state.sites) 
        fields.sites = (this.state.sites || []).join("\n");

      CampProvider.getFilterVars().forEach(id => {
        fields[id] = (forceFilters.includes(id) || this.state[id])
      });
      if (this.props.scan.alerts == 0) {
        if (this.state.arrival) fields.arrival = this.state.arrival.format("YYYY-MM-DD");
        if (this.state.departure) fields.departure = this.state.departure.format("YYYY-MM-DD");
      }
      this.props.onUpdate(this.props.scan, fields).then(json => {
        this.setState({ 
          blocking: false,
          ...json
        });
      });
      this.setState({blocking: true});
      return;
    }
    this.setState({ errors });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  isOutsideRange = (date, arrival=null) => {
    const { provider, matrix } = this.props.scan;
    if (!arrival) arrival = this.state.arrival;

    let maxNights = this.maxNights();
    let minNights = this.minNights();

    let outside = false;
    outside |= date.isBefore(moment().set({ h: 23, m: 59 }));
    if (provider == 'reservecalifornia') {
      outside |= date.isBefore(moment().add(1, 'days'));
    }
    if (matrix && this.state.focusedInput == END_DATE) {
      outside |= date.isAfter(moment(arrival).add(maxNights, "days")) ||
                 isInclusivelyBeforeDay(date, moment(arrival).add(minNights-1, "days"));
    }
    if (maxNights) {
      if (this.state.focusedInput == END_DATE) {
        outside |= date.isAfter(moment(arrival).add(maxNights, "days")) ||
                  date.isBefore(arrival);
      } /*else if (this.state.focusedInput == START_DATE && this.state.departure) {
        outside |= date.isBefore(moment(this.state.departure).add(-maxNights, "days")) ||
                  date.isAfter(this.state.departure);
      }*/
    }
    return outside;
  }

  forceFilters = () => unique(flatten(this.props.scan.force_filters || []));

  onToggle = (id) => {
    onToggle(id, this.state, this.setState.bind(this))
  }

  minNights = () => {
    const { scan } = this.props;
    const { matrix } = scan;
    let minNights = 1;
    if (matrix) minNights = 3;
    return minNights;
  }

  maxNights = () => {
    const { scan } = this.props;
    let { matrix, maxNights } = scan;

    if (matrix) maxNights = 7;
    return maxNights || 7;
  }

  onDatesChange = ({ startDate, endDate }) => {
    const arrival = startDate;
    const departure = endDate;

    if (departure && this.isOutsideRange(departure, this.state.arrival)) {
      this.setState({
        focusedInput: END_DATE
      }, () => {
        this.setState({ 
          arrival,
          departure: null
        });
      });
    } else {
      this.setState({ 
        arrival, departure
      });
    }
  }

  render() {
    const { scan } = this.props;
    const events = { onChange: this.onChange };
    const field = (name) => Object.assign({}, {
      id: name,
      name: name,
      value: this.state[name],
      className: "form-control " + (this.state.errors[name] ? "error" : "")
    });
    const { canRequire, canInclude } = CampProvider.getFilters()
    console.log("RE-RENDER");

    const forceFilters = this.forceFilters();

    const stringifyWithAnd = filters => {
      const sf = filters.filter(f => this.state[f.id]).map (f => f.name);
      if (sf.length > 1) 
        return sf.slice(0, sf.length - 1).join(", ") + " and " + sf[sf.length-1];
      return sf.join(", ")
    }

    const required = stringify(canRequire.filter(f => this.state[f.id]));
    const requiredWithAnd = stringifyWithAnd(canRequire.filter(f => this.state[f.id]));

    const selectedSites = this.state.sites || scan.details_array;

    return (
      <BlockUI tag="div" blocking={this.state.blocking}>
        <div ref={this.ref} className="csa-edit-scan" id="csa-edit-scan">
          <h2>Edit Scan</h2>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label for="scan-campground">Campground</label>
                <input type="text" id="scan-campground" name="scan-campground" className="form-control" value={scan.name} disabled={true} />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                {
                  // [x] JOHN D 
                  // if matrix scan 
                  // Start Date > End Date 
                  // for label below 
                }
                <label for="scan-length">{scan.matrix ? 'Start Date>End Date' : 'Arrival>Departure' }</label>
                <DateRangePicker
                  id="scan-length"
                  name="scan-length"
                  disabled={scan.alerts > 0}
                  className="form-control"
                  readOnly={true}
                  startDate={this.state.arrival} // momentPropTypes.momentObj or null,
                  startDateId="start_date_id" // PropTypes.string.isRequired,
                  endDate={this.state.departure} // momentPropTypes.momentObj or null,
                  endDateId="end_date_id" // PropTypes.string.isRequired,
                  onDatesChange={this.onDatesChange} // PropTypes.func.isRequired,
                  isOutsideRange={this.isOutsideRange}
                  focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                  //onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                  onFocusChange={focusedInput => {
                    const { matrix } = this.props.scan;
                    const newState = { focusedInput };
                    this.setState(newState);
                    if (focusedInput == START_DATE && this.state.departure) {
                      newState.departure = null;
                    } else if (focusedInput == END_DATE && this.state.arrival && this.state.departure) {
                      if (matrix) {
                        const minNights = this.minNights();
                        const maxNights = this.maxNights();
                        const isBefore = this.state.departure.isBefore(moment(this.state.arrival).add(minNights, 'days'));
                        const isAfter = this.state.departure.isAfter(moment(this.state.arrival).add(maxNights, 'days'));
                        console.log("arrival=", this.state.arrival.format("YYYY-MM-DD"),  " departure=", this.state.departure.format("YYYY-MM-DD"), " isBefore=", isBefore, " isAfter=", isAfter);
                        if (isBefore || isAfter) {
                          console.log(" -> WANT TO RESET END DATE");
                          newState.departure = null;
                        }
                      }
                    }
                    this.setState(newState);
                  }} // PropTypes.func.isRequired,
                  minimumNights={1}
                  required={true}
                  noBorder={true}
                  hideKeyboardShortcutsPanel={true}
                />
                </div>
            </div>
          </div>
          {
            // JOHN D 
            // this should only be shown IF there are loops or campgrounds to select
          }
{(scan.all_sites || []).length > 0 && (
          <div className="row">
            <div className="col">
              <div id="csa-app-search-results">
                    <label className="text-center">{scan.park ? "Campground(s)" : "Campsite(s)" }</label>
                    <ul>

{scan.all_sites.map (site => (
                      <li key={site}><a onClick={e => {
                        e.preventDefault();
                        this.setState(({sites=[]}) => ({ 
                          sites: !sites.includes(site) ? sites.concat(site) :
                                 sites.filter(s => s != site)
                        }))
                      }} className={C(
                        "btn", 
                        {"btn-secondary not-chosen": !selectedSites.includes(site) },
                        {"btn-primary chosen": selectedSites.includes(site) },
                      )}>{selectedSites.includes(site) ? '' : ''}{site}</a></li>
))}
                    </ul>
{/* JOHN B -- .selected class
Monitor All button doesn't need that */}
                    <div className="d-flex flex-wrap justify-content-between">
                      <a href={() => this.setState({ sites: [] })}  id="csa-app-search-results-monitor-all-btn" className={C(
                        "btn next-step btn-primary",
                        { "not-selected": !this.state.sites || this.state.sites.length > 0 },
                        { "selected": this.state.sites && this.state.sites.length == 0 }
                      )}>Monitor All</a>
                    </div>
                  </div>
            </div>
          </div>
)}
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label for="scan-email">Email Address</label>
                <input type="email" {...field("email")} id="scan-email" placeholder="Email Address" {...noAutoComplete} {...events}  />
                <Error msg={this.state.errors.email} />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label for="scan-cell">Cell Phone</label>
                <PhoneInput
                  international withCountryCallingCode 
                  defaultCountry="US"
                  placeholder="Enter Your Cell Phone Number" 
                  {...field("phone")}
                  id="scan-cell" autocomplete="off"
                  value={this.state.phone}
                  {...events}
                  onChange={value => this.setState({phone: value})}
                />
                <p className="form-text"><em><small>(International format - IE: +1 123 456 7890 for US)</small></em></p>
                <Error msg={this.state.errors.phone} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col text-center my-4">
              <h2 className="pb-0">Scan Filters</h2>
              <p className="form-text"><em><small>If available and/or applicable.</small></em></p>
            </div>
          </div>

          {/* REMOVE REQUIRE FILTERS FOR NOW 2/23/22 
          <div className="row">
            <div className="col">
              <div className="form-group mb-0 text-center">
                <h4>Require in Scan</h4>
              </div>
            </div>
          </div>
          <div className="row flex-wrap mb-4">
            <div className="col text-center">
{canRequire.map (filter => (
              <div key={filter.id} className="custom-control custom-checkbox d-inline-flex mb-1 px-4">
                <input type="checkbox" className="custom-control-input" id={filter.id} checked={this.state[filter.id]} onChange={() => this.onToggle(filter.id)} />
                <label className="custom-control-label" for={filter.id}>{filter.name}</label>
              </div>
))}

              {
                // [x] JOHN D 
                // Show this alert IF a REQUIRED Filter is checked 
                // Just expand on the list below if more than one is checked
                // So 'Electric Hookups' below would be
                // Electric Hookups, ADA / Handicap, etc. 
              }
{required && (
              <div className="alert alert-danger csa-alert" role="alert">
                Please be sure that {scan.name} offers sites with {requiredWithAnd}, otherwise your scan will not produce any alerts.
              </div>
)}
            </div>
          </div>
          */}


          <div className="row">
            <div className="col">
              <div className="form-group mb-0 text-center">
                <h4>Include in Scan</h4>
              </div>
            </div>
          </div>
          <div className="row flex-wrap mb-4">
            <div className="col text-center">
{/* -- INCLUDE FILTERS -- */}
{canInclude.map (filter => (
              <div key={filter.id} className="custom-control custom-checkbox d-inline-flex mb-1 px-4">
                <input type="checkbox" disabled={forceFilters.includes(filter.id)} className="custom-control-input" id={filter.id} checked={forceFilters.includes(filter.id) || this.state[filter.id]} onChange={() => this.onToggle(filter.id)} />
                <label className="custom-control-label" for={filter.id}>{filter.name}</label>
              </div>
))}

            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group text-center">
                <button onClick={this.onUpdate} className="btn btn-secondary">Update Scan</button>
              </div>
            </div>
          </div>
        </div>
      </BlockUI>
    )
  }
}

const Error = ({msg}) => msg && (
  <>
    {[].concat(msg).map (m => <p key={m} className="text-danger">{m}</p>)}
  </>
) || null;

function ConfirmDeleteScan(props) {
  return (
    <Modal show={true}>
      <Modal.Header>
        <Modal.Title>Are You Sure?</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Are you sure you'd like to permanently stop this scan?</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.onYes()}>Yes</Button>
        <Button variant="primary" onClick={() => props.onNo()}>No</Button>
      </Modal.Footer>
    </Modal>
  )
}

class FeedbackModal extends Component {
  constructor(props) { 
    super(props)
    this.state = { 
      errors: {},
      feedback: "",
      reservation: null,
      blocking: false
    }
  }

  onSubmit = () => {
    if (!this.state.feedback) {
      this.setState({ error: "Please provide some feedback", errors: { feedback: true } });
    } else if (this.state.reservation === null) {
      this.setState({
        error: "Please select if you were able to make a reservation or not",
        errors: { reservation: true }
      });
    } else {
      post(`/bot-backend/api/scan/${this.props.scan.id}`, {
  //    happy: this.props.happy ? 1 : -1,
        feedback: this.state.feedback,
        reservation: this.state.reservation === null ? 0 : 
                    (this.state.reservation ? 1 : -1)
      }).then(json => {
        this.props.onHide();
      }).finally(() => this.setState({blocking: false}));
      this.setState({blocking: true, error: null, errors: {} });
    }
  }

  render() {
    return (
      <Modal show={true} onHide={this.props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.scan.name}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <BlockUI tag="div" blocking={this.state.blocking}>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  {this.state.error && <div className="alert alert-danger">{this.state.error}</div>}

                  <p>Please provide us with feedback about your experience:</p>
                  <p>&nbsp;</p>
                  <textarea className={'form-control ' + (this.state.errors.feedback && 'error')} name="feedback" value={this.state.feedback} onChange={(e) => this.setState({feedback: e.target.value})} />

                  <p>&nbsp;</p>
                  <p>Were you able to make a reservation?</p>
                  <p>&nbsp;</p>
                  <ButtonGroup>
                    <Button onClick={() => this.setState({reservation:true})} variant={this.state.reservation ? 'primary' : 'secondary'}>Yes</Button>
                    <Button onClick={() => this.setState({reservation:false})}variant={this.state.reservation !== null && !this.state.reservation ? 'primary' : 'secondary'}>No</Button>
                  </ButtonGroup>
                </div>
              </div>
            </div>
          </BlockUI>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={this.onSubmit}>Submit</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const Scan = props => {
  const [ editing, setEditing ] = useState(false);
  const [ confirming, setConfirming ] = useState(false);
  const [ feedbacking, setFeedbacking ] = useState(false);
  const [ viewingReport, setViewingReport ] = useState(false);
  const [ happy, setHappy ] = useState(0);
  const [ blocking, setBlocking ] = useState(false);
  const [ thanks, setThanks ] = useState(false);
  const { scan } = props;
  const { active } = scan;
  console.log("scan", scan);
  console.log("active", active);
  const expired = !active;
  const paused = scan.status == 'paused';
  const editable = active || paused;
  const onDeleteScan = null;
  const isHappy = (happy == 1 || scan.happy == 1);
  const isUnhappy = (happy == -1 || scan.happy == -1);
  const feedback = (happiness) => {
    post(`/bot-backend/api/scan/${scan.id}`, {
      happy: happiness
    }).then(json => {
    }).finally(() => setBlocking(false));
    setBlocking(true);
    setHappy(happiness);
    setFeedbacking(true);
  }
  const onHide = () => {
    setFeedbacking(false);
    setThanks(true);
  }
  const onUpdate = (scan, fields) => {
    return props.onUpdate(scan, fields).then(json => {
      setEditing(false);
//    if (ref && ref.current) ref.current.scrollIntoView({ behavior: 'smooth' });
      const interval = setInterval( () => {
        const elt = document.querySelector(`[data-scan-id='${scan.id}']`);
        if (elt) {
          clearInterval(interval);
          elt.scrollIntoView({behavior:'smooth'})
        }
      }, 50);
      return json;
    });
  }
  const onShowMatrix = e => {
    e.preventDefault(); setViewingReport(true);
  }
  const onCloseMatrix = e => {
    setViewingReport(false);
  }

  const { canRequire, canInclude } = CampProvider.getFilters()
  const required = stringify(canRequire.filter(f => scan[f.id]));
  const included = stringify(canInclude.filter(f => scan[f.id]));

  const ref = useRef(null);
  useEffect(() => {
    const { location } = window;
    if (location.hash && location.hash.replace(/\D+/, '') == scan.id) {
      if (ref && ref.current) ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [ ref ? ref.current : null]);

  return (
    <>
    {scan.matrix && viewingReport && <MatrixModal scan={scan} show={true} onClose={onCloseMatrix} />}
    <div ref={ref} className="csa-scan-wrap" data-scan-id={scan.id}>
      {confirming && <ConfirmDeleteScan onYes={props.onDelete} onNo={() => setConfirming(false)} />}
      {feedbacking && <FeedbackModal scan={scan} happy={happy} onHide={onHide} />}
      {thanks && (
      <div className="alert alert-info">
        Thank you for your feedback
      </div>
      )}

      <BlockUI tag="div" blocking={blocking}>
      <div className={'csa-scan d-flex align-items-stretch ' + (active && 'active')}>
{ active || editable ? (
        <div className="csa-scan-actions d-flex flex-row-reverse flex-md-column-reverse justify-content-center align-items-center">
          <a onClick={() => setConfirming(true)} className="csa-scan-action-delete">Stop</a>
          <a onClick={() => setEditing(!editing)} className="csa-scan-action-edit">Edit</a>
        </div>
) : (
        <div className="csa-scan-actions d-flex flex-row-reverse flex-md-column-reverse justify-content-center align-items-center">
            <a onClick={() => feedback(1)} 
              className={"csa-scan-action-like " + (isHappy && ' selected ')}>Like</a>
            <a onClick={() => feedback(-1)} 
              className={"csa-scan-action-dislike " + (isUnhappy && ' selected ')}>Dislike</a>
        </div>
)}
        <div className="csa-scan-info d-flex align-items-center flex-wrap w-100">
          <div className="d-flex flex-wrap flex-grow-1 px-1">
            <div className="d-flex flex-wrap flex-grow-1 pb-2 w-100">
              <div className="csa-scan-location flex-grow-1">
                <h2><a target="_blank" href={scan.camp_link}>{scan.name}</a></h2>
                <h6>{scan.location || "LOCATION"}</h6>
              </div>
              <div className="csa-scan-date">
                <h3>{scan.dates}</h3>
              </div>
            </div>
            {scan.details_array && (
            <div className="csa-scan-camps pb-2 w-100">
              {scan.details_array.map ((site,i) => [
                <span key={site}>{site}</span>,
                scan.details_array.length > 1 && i < scan.details_array.length - 1 && ", "
              ])}
            </div>
            )}
            <div className="csa-scan-filters pb-2 w-100">
              {required && <p>Require: {required}</p>}
              {included && <p>Include: {included}</p>}
            </div>
          </div>
          <div className="d-flex flex-wrap w-100">
            <div className="d-flex px-1">
              <div>
                <label>Initiated</label>
                <label>Expires</label>
              </div>
              <div>
                <p>{scan.initiated}</p>
                <p>{scan.expires}</p>
              </div>
            </div>
            <div className="d-flex px-1">
              <div>
                <label>Contact</label>
                <label>Type</label>
              </div>
              <div>
                <p>{scan.contact}</p>
                <p>{scan.matrix ? 'Matrix' : 'Specific' }</p>
              </div>
            </div>
            <div className="d-flex px-1">
              <div>
                <label>Freq.</label>
                <label># runs</label>
              </div>
              <div>
                <p>{scan.frequency} min.</p>
                <p>{scan.runs}</p>
              </div>
            </div>
            <div className="d-flex px-1">
              <div>
                <label>Alerts</label>
                {scan.matrix && <label>Matrix</label>}
              </div>
              <div>
                <p>{scan.alerts}</p>
                {scan.matrix && scan.runs > 0 && <p><a href="" className="btn btn-primary" onClick={onShowMatrix}>View Report</a></p>}
                {scan.matrix && !scan.runs && <p>Scanning&hellip;</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
      { editing && <EditScan key={scan} scan={scan} onUpdate={onUpdate} /> }
      {(scan.alerts_history || []).length > 0 && (
      <div className="csa-scan-alerts">
        <ul>
          {scan.alerts_history.map(alert => <li key={alert}>{alert}</li>)}
        </ul>
      </div>
      )}
      </BlockUI>
    </div>
    </>
  );
}

export default class ScansPage extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      success: null,
      scans: [],
      loading: true
    }
  }

  refresh = () => {
    let params = new URLSearchParams(this.props.location.search);
    if (params.has("success")) {
      this.setState({ success: params.get("success").replace(/#.*/, '') });
    }
    this.setState({loading: true});
    get('/bot-backend/api/scans').then(json => this.setState({scans: json, loading: false}));
  }

  onUpdate = (scan, fields) => {
    this.setState(ps => { 
      ps.scans.filter(s => s.id == scan.id).every(s => Object.entries(fields).forEach(([k,v]) => s[k] = v));
      let ss = [].concat(ps.scans);
      return {
        blocking: true,
        scans: ss
      }
    });
    return post(`/bot-backend/api/scan/${scan.id}`, {
      ...fields
    }).then(json => {
      if (json.errors && Object.keys(json.errors).length > 0) return json;

      this.setState(ps => { 
        ps.scans.filter(s => s.id == scan.id).every(s => Object.entries(json).forEach(([k,v]) => s[k] = v));
        let ss = [].concat(ps.scans);
        return {
          scans: ss,
          success: "Scan updated" 
        }
      });

      return json;
    }).finally(() => this.setState({ blocking: false }));
  }

  componentDidMount() {
    if (!this.context.isLoggedIn() /*&& !this.context.isUnconfirmed()*/) {
      this.props.history.push("/login");
    } else this.refresh();
  }

  onDelete = (scan) => {
    post(`/bot-backend/api/scan/${scan.id}`, {
      cancelled: true
    }).then(json => {
    }).finally(() => this.setState({ blocking: false }));
    this.setState(ps => { 
      ps.scans.filter(s => s.id == scan.id).every(s => s.active = false);
      return {
        success: "Your scan has been marked inactive. Please click the Thumbs Up or Thumbs Down icons to leave us feedback.",
        blocking: true,
        scans: ps.scans
      }
    });
  }

  render() {
    const noAccount = !this.context.isLoggedIn();

    const scans = this.state.scans || [];
    const activeScans = scans.filter(scan => scan.active);
    const pausedScans = scans.filter(scan => scan.status == 'paused');
    const expiredScans = scans.filter(scan => !scan.active && scan.status != 'paused');
    const { scanCredits } = this.context;

    return (
      <>
        {
          // #csa-users-header should probably be a component 
        }
        <div id="csa-users-header" className="d-flex flex-wrap justify-content-between align-items-center">
          <h1 className="flex-grow-1"><span id="csa-brand">Campsite <span>Assist</span></span></h1>
          <div id="csa-users-header-btns" className="d-flex flex-wrap justify-content-between">
            <div><Link to="/campsite-assist" className="btn btn-primary">New Scan</Link></div>
{!scanCredits &&(
            <div><Link to="/packs" className="btn btn-primary">Scan Packs</Link></div>
)}
          </div>
        </div>        
{!this.state.loading && scans.length == 0 && <h3>You don't have any scans currently running. Please click <Link to="/campsite-assist">NEW SCAN</Link> above to create your first scan.</h3> }
{activeScans.length > 0 && (
        <div id="csa-active-scans" className="csa-scans">
          <div className="row">
            <div className="col">
              { this.state.success && <div className="alert alert-info">{this.state.success}</div> }
              { noAccount && (
              <div className="alert alert-warning">
                We noticed your email address is not associated with an account. <Link to="/create" className="alert-link">Create an Account</Link>
              </div>
              )}
              <h3>Active Scans</h3>
{activeScans.map(scan => (
              <Scan key={scan.id} scan={scan} onDelete={() => this.onDelete(scan)} onUpdate={this.onUpdate} />
))}
            </div>
          </div>
        </div>
)}
{pausedScans.length > 0 && (
        <div id="csa-paused-scans" className="csa-scans">
          <div className="row">
            <div className="col">
              <h3>Paused Scans</h3>
{pausedScans.map(scan => (
              <Scan key={scan.id} scan={scan} onDelete={() => this.onDelete(scan)} onUpdate={this.onUpdate} />
))}
            </div>
          </div>
        </div>
)}
{expiredScans.length > 0 && (
        <div id="csa-expired-scans" className="csa-scans">
          <div className="row">
            <div className="col">
              <h3>Expired Scans</h3>
              {expiredScans.map(scan => <Scan key={scan.id} scan={scan} />)}
            </div>
          </div>
        </div>
)}
      </>
     );
  }
}
