import React, { Component, useContext } from 'react';
import CampProvider from '../providers/CampProvider.js';
import { withUser } from '../providers/UserProvider'
import { Modal, Button } from 'react-bootstrap';
import CampContext, { Steps } from '../contexts/CampContext.js';
import UserContext from '../contexts/UserContext.js';
import C from 'classnames';
import AppStep from '../components/AppStep'

class SpecificFrequencyStep extends Component {
  static contextType = CampContext;

  constructor(props) {
    super(props);
    this.state = { method: 'scan', confirming: false };
  }

  onSelect = (frequency) => {
    console.log("onSelect: ", frequency);
    if (frequency == 33) {
      this.setState({confirming: true}); return;
    } else this.setState({confirming: false});
    this.context.onFrequency(frequency);
    this.context.onStep(Steps.PAYMENT);
  }

  onPack = (pack) => {
    console.log("onPack: ", pack);
    this.context.onPack(pack);
    this.context.onStep(Steps.PAYMENT);
  }

  onConfirm = () => {
    this.context.onFrequency(33);
    this.setState({confirming: false});
    this.context.onStep(Steps.PAYMENT);
  }

  onMethod = (method) => {
    this.setState({method: method});
  }

  onUse = () => {
    this.context.onFrequency(3);
    this.context.setState({wantsToPay: false});
    this.context.onStep(Steps.PAYMENT);
  }

  render() {
    let frequencies = CampProvider.getFrequencyOptions();
    if (this.props.user.subscription) {
      frequencies = this.context.getMembershipOptions(frequencies);
    }
    const { step, matrix, wantsToPay } = this.context;
    const { scanCredits=0 } = (this.props.user || {});
    const scanStyle = {
      display: this.state.method == 'scan' ? '' : 'none'
    };
//  console.log({ ...this.props, ...this.state });
    const { method, frequency, pack } = { ...this.props, ...this.state, ...this.context };
    console.log(` now method=${method} frequency=${frequency}`)
    return (
      <>
        <Modal show={this.state.confirming} centered className="csa-modal" onHide={this.onConfirm}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="csa-info-panel">
              <h2>Are You Sure?</h2>
              <p>Scanning only once every 33 minutes <span className="text-danger">significantly lowers</span> your chance of finding an available campground.</p>
              <p><strong>We recommend scanning at least every 13 minutes.</strong></p>
              <p>Really scan only once every 33 minutes?</p>
              <div className="text-center pt-4 pb-2 d-flex flex-wrap justify-content-around">
                <Button variant="primary" className="m-2" onClick={() => this.onSelect(13) }>Scan more frequently</Button>
                <Button variant="secondary" className="m-2" onClick={this.onConfirm}>I'll take the risk</Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        
        <AppStep id="csa-app-freq" idx={Steps.FREQUENCY}>
          <label htmlFor="csa-app-scan-freq">Select your {matrix ? 'Matrix' : 'Specific'} Scan frequency</label>
          {
          // JOHN D
          // IF a USER has an existing active Scan Pack
          // Show this below
          }
{!matrix && scanCredits > 0 && (
          <div id="csa-app-has-packs">
            <h6><span className="d-inline-block">Your account has ({scanCredits}) 3 Minute Scans available. </span>
            <span className="d-inline-block">Would you like to use (1) for this scan?</span></h6>
            <button className="btn btn-primary" onClick={this.onUse}>USE (1) 3 Minute Scan</button>
          </div>
)}
{!matrix && !scanCredits && (
          <div id="csa-app-pay-type" className="btn-group d-flex" role="group" aria-label="Basic example">
            <button type="button" className={'btn btn-primary w-50 ' + (method == 'scan' && 'chosen')} onClick={() => this.onMethod('scan')}>Pay-per-scan</button>
            <button type="button" className={'btn btn-primary w-50 ' + (method == 'pack' && 'chosen')} onClick={() => this.onMethod('pack')}>Scan Packs</button>
          </div>
)}
{(step == Steps.FREQUENCY || !pack) && (
          <div id="csa-app-pay-option" className="card-group" style={scanStyle}>
{frequencies.map (freq => (
            <div key={freq[0]} className={'card ' + (frequency == freq[0] && 'chosen')}>
              <div className="card-header">
                <h5>{freq[0]} Minutes</h5>
              </div>
              <div className="card-body">
                <h4 className="csa-app-price">
                  {step == Steps.FREQUENCY || wantsToPay || !scanCredits ? `$${freq[1]}` : "(1) SCAN"}
                  <span className="d-block">
                    <small>(One-Time Fee)</small>
                  </span>
                </h4>
                <ul className="card-text">
                  <li>Scans every {freq[0]} minutes</li>
                  <li>Alerts for every found campsite</li>
                  <li>Scan runs for up to {freq[2]}</li>
                  <li className="pt-4 csa-pay-type-btn"><button className="btn btn-primary" onClick={() => this.onSelect(freq[0])}>Select</button></li>
                </ul>
              </div>
            </div>
))}
          </div>
)}
          <p className="form-text" style={scanStyle}><em>The more often your scan runs &ndash; the higher chances of you securing a reservation!</em></p>
          {!scanCredits && <ScanPacks pack={pack} visible={method == 'pack'} onPack={this.onPack} />}
        </AppStep>
      </>
    )
  }
}

class MatrixFrequencyStep extends Component {
  static contextType = CampContext;

  constructor(props) {
    super(props);
    this.state = { confirming: false };
  }

  onSelect = (frequency) => {
    console.log("onSelect: ", frequency);
    if (frequency == 90) {
      this.setState({confirming: true}); return;
    } else this.setState({confirming: false});
    this.context.onFrequency(frequency);
    this.context.onStep(Steps.PAYMENT);
  }

  onConfirm = () => {
    this.context.onFrequency(90);
    this.setState({confirming: false});
    this.context.onStep(Steps.PAYMENT);
  }

  render() {
    let frequencies = CampProvider.getMatrixOptions();
    if (this.props.user.subscription) {
      frequencies = this.context.getMembershipOptions(frequencies);
    }
    const { step, matrix } = this.context;
    const { frequency } = { ...this.props, ...this.state, ...this.context };
    return (
      <>
        <Modal show={this.state.confirming} centered className="csa-modal" onHide={this.onConfirm}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="csa-info-panel">
              <h2>Are You Sure?</h2>
              <p>Scanning only once every 90 minutes <span className="text-danger">significantly lowers</span> your chance of finding an available campground.</p>
              <p><strong>We recommend scanning at least every 45 minutes.</strong></p>
              <p>Really scan only once every 90 minutes?</p>
              <div className="text-center pt-4 pb-2 d-flex flex-wrap justify-content-around">
                <Button variant="primary" className="m-2" onClick={() => this.onSelect(45) }>Scan more frequently</Button>
                <Button variant="secondary" className="m-2" onClick={this.onConfirm}>I'll take the risk</Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        
        <AppStep id="csa-app-freq" idx={Steps.FREQUENCY}>
          <label htmlFor="csa-app-scan-freq">Select your Matrix Scan frequency</label>
          <div id="csa-app-pay-option" className="card-group">
{frequencies.map (freq => (
            <div key={freq[0]} className={'card ' + (frequency == freq[0] && 'chosen')}>
              <div className="card-header">
                <h5>{freq[0]} Minutes</h5>
              </div>
              <div className="card-body">
                <h4 className="csa-app-price">
                  ${freq[1]}
                  <span className="d-block">
                    <small>(One-Time Fee)</small>
                  </span>
                </h4>
                <ul className="card-text">
                  <li>Scans every {freq[0]} minutes</li>
                  <li>Report of Availability</li>
                  <li>Scan runs for up to {freq[2]}</li>
                  <li className="pt-4 csa-pay-type-btn"><button className="btn btn-primary" onClick={() => this.onSelect(freq[0])}>Select</button></li>
                </ul>
              </div>
            </div>
))}
          </div>
          <p className="form-text"><em>The more often your scan runs &ndash; the higher chances of you securing a reservation!</em></p>
        </AppStep>
      </>
    )
  }
}

const FrequencyStep = (props) => {
  const { matrix } = useContext(CampContext);
  return matrix ? <MatrixFrequencyStep {...props} /> : <SpecificFrequencyStep {...props} />
}

export default withUser(FrequencyStep);

export const ScanPacks = ({pack, visible, onPack}) => {
  const user = useContext(UserContext);
  const { scanCredits=0 } = user;

  return (
    <>
      <div id="csa-app-pack-options" className="card-group" style={{display:visible ? '' : 'none'}}>
{CampProvider.getPackOptions().map (([count,price]) => (
        <div key={count} className={C("card", { chosen: pack == count })}>
          <div className="card-header">
            <h5>{count} Pack</h5>
          </div>
          <div className="card-body">
            <h4 className="csa-app-price">${price}<span className="d-block"><small>(One-Time Fee)</small></span></h4>
            <ul className="card-text">
              <li className="csa-packs-savings">{(100 * (1.0 - (price/count) / 20)).toFixed(0)}% Savings!</li>
              <li>${count * 20 } Regular Price</li>
              <li>${(price / count).toFixed(2).replace(".00", "")} per Scan</li>
              <li className="pt-4 csa-pay-type-btn"><button className="btn btn-primary" onClick={() => onPack(count)}>Select</button></li>
            </ul>
          </div>
        </div>
))}
      </div>
      <p className="form-text text-center" style={{display: visible ? '' : 'none'}}><em>All Packs are 3 Minute Scans. Unused Scans expire 24 Months from Purchase Date.</em></p>
    </>
  )
}