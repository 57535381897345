import React from 'react';

const MembershipContext = React.createContext();

const Steps = {
  START: 0,
  MEMBERSHIP: 1,
  EMAIL: 2,
  PAYMENT: 3, CHECKOUT: 3,
  THANKS: 4
}

export { MembershipContext as default, Steps };