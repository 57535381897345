import React, { Component } from 'react';
import CampContext, { Steps } from '../contexts/CampContext.js';
import AppStep from '../components/AppStep'
import C from 'classnames';
import ScanTypesModal from '../modals/ScanTypesModal'

class ScanStep extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  onSpecific = e => {
    e.preventDefault();
    this.context.onSpecific();
    this.context.onStep(Steps.WHEN);
  }

  onMatrix = e => {
    e.preventDefault();
    this.context.onMatrix();
    this.context.onStep(Steps.WHEN);
  }

  onModal = e => {
    e.preventDefault();
    this.setState({modal: true});
  }

  render() {
    const { step, scan } = this.context;
    const active = step == Steps.SCAN;
    const specific  = active || (!active && !this.context.matrix);
    const matrix = active || (!active && this.context.matrix);
    return (
      <>
      <ScanTypesModal show={this.state.modal} cta={"Got It"} onClose={() => this.setState({modal: false})} />
      <AppStep id="csa-app-scan-type" idx={Steps.SCAN}>
        <label for="csa-app-search">What kind of Scan do you want to create?</label>
        <div id="csa-scan-type-btns" className="row justify-content-center">
          <div id="csa-specific-scan-col" className={C(
            "col-auto",
            { selected: scan == 'specific' }
           )} style={{display: specific ? '' : 'none' }}>
            <a href="" id="csa-specific-scan-btn" className="btn btn-secondary" onClick={this.onSpecific}>Specific Scan</a>
            {active && <p className="form-text"><em>Find an exact length of stay</em></p>}
          </div>
          <div id="csa-matrix-scan-col" className={C(
            "col-auto",
            { selected: scan == 'matrix' }
           )} style={{display: matrix ? '' : 'none'}}>
            <a href="" id="csa-matrix-scan-btn" className="btn btn-secondary" onClick={this.onMatrix}>Matrix Scan</a>
            {active && <p className="form-text"><em>Find anything within a range</em></p>}
          </div>
        </div>
        {
          // [x] JOHN D -- link the Learn More text below to the Scan Types modal
        }
        {active && <p className="form-text"><a href="" className="muted" onClick={this.onModal}>Learn More About Our Scan Types</a></p>}
      </AppStep>
      </>
    )
  }
}
ScanStep.contextType = CampContext;

export default ScanStep;