import React, { useContext, useRef, useEffect } from 'react';
import MembershipContext, { Steps } from '../contexts/MembershipContext.js';
import C from 'classnames';

const MembershipAppStep = ({idx, children, ...props}) => {
  const { step, onStep } = useContext(MembershipContext);
  const ref = useRef(null);

  const past = step > idx;
  const events = past ? {
    onClick: () => onStep(idx)
  } : {}

  useEffect(() => {
    const active = step != Steps.MEMBERSHIP && step != Steps.THANKS && step == idx;
    if (active) {
      console.log('scroll');
      setTimeout(() => {
        if (ref && ref.current) ref.current.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
  }, [ ref, ref.current ]);

  return (
    <div ref={ref} {...props} {...events} className={C(
      "csa-app-step",
      { 
        active: step != Steps.MEMBERSHIP && step != Steps.THANKS && step == idx,
        // [x] how do we also include the THANKS step?
        show: step == idx,
        past
      },
    )}>{children}</div>
  )
}

export default MembershipAppStep;