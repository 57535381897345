import React, { Component, useContext } from 'react';
import 'react-dates-custom-month-year-navigation/initialize';
import { isInclusivelyBeforeDay, DateRangePicker } from 'react-dates-custom-month-year-navigation';
import moment from 'moment';
import 'react-dates-custom-month-year-navigation/lib/css/_datepicker.css';
import CampContext, { Steps } from '../contexts/CampContext.js';
import { START_DATE, END_DATE } from 'react-dates-custom-month-year-navigation/constants';
import AppStep from '../components/AppStep'
import C from 'classnames';

export default class WhenStep extends Component {
  constructor() {
    super();
    this.state = { focusedInput: START_DATE }
  }

  minNights = () => {
    const { matrix } = this.context;
    let minNights = 1;
    if (matrix) minNights = 3;
    return minNights;
  }

  maxNights = () => {
    const { matrix, camp } = this.context;
    let { maxNights } = camp;

    if (matrix) maxNights = 7;
    return maxNights || 7;
  }

  onDatesChange = ({ startDate, endDate }) => {
    if (endDate && this.isOutsideRange(endDate, this.context.startDate)) {
      this.setState({
        focusedInput: END_DATE
      }, () => {
        this.context.setState({ 
          startDate,
          endDate: null
        });
      });
    } else {
      this.context.setState({ 
        startDate, endDate
      });
    }
    if (this.props.onChanged) this.props.onChanged();
  }

  isOutsideRange = (date, startDate=null) => {
    if (!startDate) startDate = this.context.startDate;

//  console.log("isOutsideRange: ", date);
    if (!this.context.camp) return false;
    const { matrix } = this.context;

    const { provider } = this.context.camp;
    const maxNights = this.maxNights();
    const minNights = this.minNights();

    let outside = false;
    outside |= date.isBefore(moment().set({ h: 23, m: 59 }));
    if (provider == 'reservecalifornia') {
      outside |= date.isBefore(moment().add(1, 'days'));
    }
    if (matrix && this.state.focusedInput == END_DATE) {
      outside |= date.isAfter(moment(startDate).add(maxNights, "days")) ||
                 isInclusivelyBeforeDay(date, moment(startDate).add(minNights-1, "days"));
    }
    if (maxNights) {
      if (this.state.focusedInput == END_DATE) {
        outside |= date.isAfter(moment(startDate).add(maxNights, "days")) ||
                  date.isBefore(startDate);
      } /*else if (this.state.focusedInput == START_DATE && this.context.endDate) {
        outside |= date.isBefore(moment(this.context.endDate).add(-maxNights, "days")) ||
                  date.isAfter(this.context.endDate);
      }*/
    }
    return outside;
  }

  render() {
    let nights = undefined;
    const ctx = this.context;
    const { step, camp={}, matrix } = ctx;
    let { startDate, endDate } = ctx;
    const startStr = (matrix ? "start" : "arrival");
    const endStr = (matrix ? "end" : "departure");
    const placeholderStart = startStr[0].toUpperCase() + startStr.slice(1);
    const placeholderEnd = endStr[0].toUpperCase() + endStr.slice(1);
    let infoMsg = 
      "Choose your " + (startDate ? endStr : startStr) + " date";
    if (startDate && endDate) {
      nights = endDate.diff(startDate, 'days');
      if (nights <= 0) nights = undefined;
      infoMsg = matrix ? 
                "Review your start and end dates" :
                "Review your date of arrival and departure";
    }
    const an = (nights == 8 ? 'an' : 'a');
    console.log("rendering with focusedInput = ", this.state.focusedInput)
    let focusedInput = this.state.focusedInput || START_DATE;
    if (step != Steps.WHEN) focusedInput = null;

    const titleMsg = matrix ? "What timeframe do you want to scan?" : "When do you want to camp?";

    console.log(`RENDER: nights=${nights} maxNights=${this.maxNights()} minNights=${this.minNights()}`);

    return (
      <AppStep id="csa-app-when" idx={Steps.WHEN}>
        <label htmlFor="csa-app-date">{titleMsg}</label>
        {
          // JOHN D 
          // Matrix Scans only allow 3-7 nights (for now...)
        }
        <div className={C({
          "has-start-date": this.context.startDate,
          "has-end-date": this.context.endDate
        })}>
          <DateRangePicker
            id="csa-app-date"
            className="form-control"
            numberOfMonths={1}
            appendToBody={false}
            transitionDuration={0}
            readOnly={true}
            startDatePlaceholderText={placeholderStart}
            endDatePlaceholderText={placeholderEnd}
            startDate={this.context.startDate} // momentPropTypes.momentObj or null,
            startDateId="start_date_id" // PropTypes.string.isRequired,
            endDate={this.context.endDate} // momentPropTypes.momentObj or null,
            endDateId="end_date_id" // PropTypes.string.isRequired,
            onDatesChange={this.onDatesChange}
            isOutsideRange={this.isOutsideRange}
            focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={focusedInput => {
              const newState = { focusedInput };
              console.log("onFocusChange: ", focusedInput, " endDate=", this.context.endDate);
              if (focusedInput == START_DATE && this.context.endDate) {
                newState.endDate = null;
              } else if (focusedInput == END_DATE && this.context.startDate && this.context.endDate) {
                if (matrix) {
                  const minNights = this.minNights();
                  const maxNights = this.maxNights();
                  const isBefore = this.context.endDate.isBefore(moment(this.context.startDate).add(minNights, 'days'));
                  const isAfter = this.context.endDate.isAfter(moment(this.context.startDate).add(maxNights, 'days'));
                  console.log("startDate=", this.context.startDate.format("YYYY-MM-DD"),  " endDate=", this.context.endDate.format("YYYY-MM-DD"), " isBefore=", isBefore, " isAfter=", isAfter);
                  if (isBefore || isAfter) {
                    console.log(" -> WANT TO RESET END DATE");
                    newState.endDate = null;
                  }
                }
              }
              this.setState(newState);
            }} // PropTypes.func.isRequired,
            minimumNights={1}
            required={true}
            noBorder={true}
            hideKeyboardShortcutsPanel={true}
            keepOpenOnDateSelect={true}
            calendarInfoPosition="top" 
            renderCalendarInfo={() => <h6>{infoMsg}</h6>} 
            customArrowIcon={<div className="DRP-svg-icon forward-arrow"></div>}
            navPrev={<div className="DRP-svg-icon backward-arrow"></div>}
            navNext={<div className="DRP-svg-icon forward-arrow"></div>}
          />
{step == Steps.WHEN && nights && (
        <>
          {(nights < this.minNights() || nights > this.maxNights()) ? (
            <div>
              <div className="mx-auto"><h5>
                Please select between {this.minNights()} and {this.maxNights()} nights
             </h5></div>
              <a disabled={true} className="btn btn-primary btn-continue disabled" 
                onClick={e => e.preventDefault()}>Continue</a>
            </div>
          ) : (
            // [x] JOHN D 
            // FOR MATRIX SCANS THE MESSAGE IS 
            // "You are scanning a/an 5 night span"
            <div>
              <div className="mx-auto"><h5>
                {matrix ? `You are scanning ${an} ${nights} night span` : 
                          `You have selected ${an} ${nights} night stay`}</h5></div>
              <a className="btn btn-primary btn-continue" 
                onClick={() => ctx.onStep(Steps.EMAIL)}>Continue</a>
            </div>
          )}
        </>
)}
        </div>
      </AppStep>
    );
  }
}
WhenStep.contextType = CampContext;

