import React, { Component } from 'react';
import UserContext from '../contexts/UserContext.js';
import CampProvider from '../providers/CampProvider.js';
import 'react-block-ui/style.css';
import { withRouter } from 'react-router'
import {CardElement, injectStripe, Elements } from 'react-stripe-elements';
import { Link } from 'react-router-dom';
import { ScanPacks } from '../steps/FrequencyStep'
import Spinner from '../components/Spinner.js';
import PacksHeader from '../components/PacksHeader';

// [x] JOHN D 
// clean this file and remove anything we don't need for the Buy Packs Page

class BuyPacksPage extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
    }
    this.ref = React.createRef();
  }

  componentDidMount() {
    if (!this.context.isLoggedIn() /*&& !this.context.isUnconfirmed()*/) {
      this.props.history.push("/login");
    }
  }

  submit = async (e) => {
    this.setState({pending: true});

    let token = null;

    let stripe_response = await this.props.stripe.createToken({});
    console.log("STRIPE: GOT RESPONSE: ", stripe_response);
    const { error } = stripe_response;
    // error.message
    if (error) {
      this.setState({error: `Error: ${error.message}`, pending: false});
      return;
    }
    token = stripe_response.token;

    const json = await this.context.onBuyPack(token, this.state.pack);
    if (json.error) {
      this.setState({error: json.error, pending: false});
      return;
    }

    this.setState({
      error: null,
      pending: false
    });

    this.props.history.push({
      pathname: "/packs",
      search: "?success=Pack+purchase+successful!"
    });
  }

  componentDidUpdate() {
    if (this.state.pack && this.ref && this.ref.current) {
      this.ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  render() {
//  const { scanCredits } = this.context;
    const scanCredits = 0;

    const { pack } = this.state;
    const price = (CampProvider.getPackOptions().find(p => p[0] == pack) || {})[1];

    const style = {
      base: {
        fontFamily: 'Open Sans, Segoe UI, sans-serif',
        fontWeight: 700,
        fontSize: '18px'
      }
    };

    return (
        <div id="csa-buy-packs">
          <PacksHeader />
{
  // [x] JOHN D 
  // start of the buy packs page view
  // url should be /packs/buy 
  // for reference: https://704one.com/clients/csa/csp-user-csa-buy-packs.html 
  // 
}
          {
            // [x] JOHN D 
            // most users should never see this alert 
            // but if they do show it 
          }
{scanCredits > 0 ? (
          <div className="row">
            <div className="col">
              <div className="alert alert-danger csa-alert" role="alert">
                Your account currently has an Active Scan Pack. <br />
                Please use the remaining Scans in your Pack to purchase another Pack. 
              </div>
            </div>
          </div>
) : (
          <div className="row">
            <div className="col">
              <h3>Buy Additional Scan Packs</h3>
              
              <div id="csa-app-packs" className="csa-app-step">
                <ScanPacks visible={true} pack={pack} onPack={pack => this.setState({pack})} />
              </div>
              
              <div ref={this.ref} id="csa-app-checkout" className="csa-app-step" style={{display: pack ? '' : 'none'}}>

                <div>
                  <label htmlFor="csa-app-pay">Enter your payment information</label>
                  {this.state.error && <p className="checkoutError">{this.state.error}</p>}
                  <div className="stripe-holder">
                    <CardElement className="form-control" style={style} />
                  </div>
                  <p className="form-text"><i id="csa-app-icon-lock"></i> Your information is secure</p>
                </div>

                {this.state.error && (
                  <div className="alert alert-danger csa-alert" role="alert">
                    {this.state.error}
                  </div>
                )}

                <div id="csa-app-last" className="csa-app-step">
                  <button className="btn btn-lg btn-primary" onClick={this.submit} disabled={this.state.pending || !pack}><Spinner loading={this.state.pending} /> 
                  Pay ${price} to Buy Pack
                  </button>
                </div>

              </div>

            </div>
          </div>
)}
      </div>
    )
  }
}

const Thanks = () => (
  <div id="csa-app-thanks" class="csa-app-step">
    
    <h2>Thank you for your order!</h2>

    <p><Link to="/packs"><a href="">Your Pack</a></Link> has been added to your account.</p>

    <div class="pt-5 pb-3 d-flex flex-wrap justify-content-center">
      <Link to="/campsite-assist"><a class="btn btn-primary" href="/">Create a New Scan</a></Link>
      <Link to="/scans"><a class="btn btn-primary" href="/scans">See My Scans</a></Link>
      <Link to="/packs"><a class="btn btn-primary" href="/packs">See My Packs</a></Link>
    </div>

  </div>
)

export default injectStripe(withRouter(BuyPacksPage));