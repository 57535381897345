import React, { Component } from 'react';
import UserContext from '../contexts/UserContext.js';
import MaskedInput from 'react-maskedinput'
import EmailValidator from 'email-validator'
import CampProvider from '../providers/CampProvider';
import { post } from '../ajax.js';
import BlockUI from 'react-block-ui';
import noAutoComplete from '../lib/noAutoComplete.js';
import 'react-block-ui/style.css';
import PhoneInput from 'react-phone-number-input'

export default class AccountPage extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.accountVars = [ "username", "firstname", "lastname", "phone", "email" ].concat(CampProvider.getFilterVars())
    this.state = {
      errors: {},
      blocking: false,
      ...this.readContext()
    };
    this.ckb = CampProvider.getFilterVars();
  }

  readContext() {
    const ctx = this.context || {};
    let state = {};
    this.accountVars.forEach(v => state[v] = ctx[v] || "");
    return state;
  }

  componentDidMount() {
    if (!this.context.isLoggedIn()) {
      if (this.context.isUnconfirmed()) {
        this.props.history.push("/login");
      } else {
        console.log("AccountPage -> redirecting to /create");
        this.props.history.push("/create");
      }
    } else {
      this.setState(this.readContext());

      let params = new URLSearchParams(this.props.location.search);
      if (params.has("success")) {
        this.setState({ success: params.get("success") });
      }

    }
  }

  onSave = () => {
    let errors = {}, post = {};
    this.accountVars.forEach(v => {
      if (!this.ckb.includes(v) && !this.state[v]) errors[v] = true;
      post[v] = this.state[v];
    });
    if (this.state.phone.replace(/[^0-9]+/g, '').length < 10) errors.phone = true;
    if (!EmailValidator.validate(this.state.email)) errors.email = true;
    if (Object.keys(errors).length > 0) { this.setState({errors}); return; }

    this.context.onUpdateAccount(post).then(json => {
      if (json.errors) {
        this.setState({ blocking: false, errors: json.errors });
        return;
      }
      this.setState({ blocking: false, success: "Your account has been updated" });
    });
    this.setState({ errors:{}, blocking: true, success: null });
  }

  onUpdateSettings = () => {
    const post = {}
    this.ckb.forEach(ckb => post[ckb] = this.state[ckb]);
    this.context.onUpdateAccount(post).then(json => {
      if (json.errors) {
        this.setState({ blocking: false, errors: json.errors });
        return;
      }
      this.setState({ blocking: false, success: "Your settings have been updated" });
    });
    this.setState({ errors:{}, blocking: true, success: null });
  }

  onVerifyPhone = (e) => {
    e.preventDefault();
  }

  onVerifyEmail = (e) => {
    e.preventDefault();
  }

  onUpdatePassword = () => {
    let errors = {};
    if ((this.state.password || "").length < 6) errors.password = "Please make your password at least 6 characters"
    if (this.state.password != this.state.password2) {
      errors.password = errors.password2 = "Passwords do not match";
    }
    if (Object.keys(errors).length > 0) { this.setState({errors}); return; }
    post('/bot-backend/api/user', { password: this.state.password }).then(json => {
      this.setState({ password: "", password2: "", blocking: false, success: "Password updated" });
    });
    this.setState({ errors: {}, blocking: true, success: null });
  }

  onChange = (e) => {
    if (this.ckb.includes(e.target.name)) {
      let name = e.target.name;
      this.setState(ps => ({ [name]: !ps[name] }));
    } else this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const events = {
      onChange: this.onChange
    };
    const field = (name) => Object.assign({}, {
      id: name,
      name: name,
      value: this.state[name],
      className: "form-control " + (this.state.errors[name] ? "error" : ""),
      ...events,
      ...noAutoComplete
    });
    return (
      <>
        <BlockUI tag="div" blocking={this.state.blocking}>
        <h1>User Account</h1>
          <div id="csp-user-account">
            { this.state.success && <div className="alert alert-info">{this.state.success}</div> }
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label for="username">Username</label>
                  <input type="text" {...field("username")} placeholder="Username" />
                  <Error msg={this.state.errors.username} />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label for="firstname">First Name</label>
                  <input type="text" {...field("firstname")} placeholder="First Name" />
                  <Error msg={this.state.errors.firstname} />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label for="lastname">Last Name</label>
                  <input type="text" {...field("lastname")} placeholder="Last Name" />
                  <Error msg={this.state.errors.lastname} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col col-sm-4">
                <div className="form-group">
                  <label for="cellphone">Cell Phone</label>
                    <PhoneInput
                      international withCountryCallingCode 
                      defaultCountry="US"
                      placeholder="Enter Your Cell Phone Number" 
                      {...field("phone")}
                      id="cellphone" autocomplete="off"
                      value={this.state.phone}
                      onChange={value => this.setState({phone: value}) }
                    />

{/*                  <input type="tel" id="cellphone" name="cellphone" className="form-control verified" placeholder="Cell Number" value="(760) 845-5924" /> */}
{/*                  <span className="input-note"><a href="#" onClick={this.onVerifyPhone}>Verify</a></span>*/}
                  <p className="form-text"><em><small>(International format - IE: +1 123 456 7890 for US)</small></em></p>
                  <Error msg={this.state.errors.phone} />
                </div>
              </div>
              <div className="col">
{ /*className="form-control verified"*/ }
                <div className="form-group">
                  <label for="emailaddr">Email Address</label>
                  <input type="email" {...field("email")} id="emailaddr" placeholder="Email Address" />
{/*                  <span className="input-note"><a href="#" onClick={this.onVerifyEmail}>Verify</a></span>*/}
                  <Error msg={this.state.errors.email} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <button className="btn btn-primary" onClick={this.onSave}>Save</button>
                </div>
              </div>
            </div>
          </div>
        <div id="csp-user-update-pwd">
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label for="newpwd">Update Password</label>
                <input type="password" {...field("password")} id="newpwd" placeholder="New Password" />
                <Error msg={this.state.errors.password} />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label for="confpwd">Re-Enter Password</label>
                <input type="password" {...field("password2")} id="confpwd" placeholder="Re-Enter Password" />
                <Error msg={this.state.errors.password2} />
              </div>
            </div>
            <div className="col"></div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <button className="btn btn-primary" onClick={this.onUpdatePassword}>Update</button>
              </div>
            </div>
          </div>
        </div>
        </BlockUI>
      </>
    );
  }
}

const Error = ({msg}) => msg && (
  <>
    {[].concat(msg).map (m => <p key={m} className="text-danger">{m}</p>)}
  </>
) || null;
