import React, { Component } from 'react';
import EmailValidator from 'email-validator'
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css'
import CampContext, { Steps } from '../contexts/CampContext.js';
import MaskedInput from 'react-maskedinput'
import noAutoComplete from '../lib/noAutoComplete.js';
import C from 'classnames';
import AppStep from '../components/AppStep'

export default class EmailStep extends Component {
  constructor(props) {
    super(props);
    this.state = { emailError: false };
    this.emailRef = React.createRef();
  }

  onEmail = () => {
    const email = this.emailRef.current.value;
    if (EmailValidator.validate(email)) {
      this.context.setState({email: email});
      this.setState({emailError: false})
      this.context.onStep(Steps.PHONE);
      if (this.emailRef.current) this.emailRef.current.blur();
      if (this.props.onEmail) this.props.onEmail();
    } else {
      this.context.setState({email: null});
      this.setState({emailError: true})
    }
  }
   
  render() {
    const ctx = this.context;
    const { step } = ctx;
    const { emailError } = this.state;
    return (
      <AppStep id="csa-app-user-email" idx={Steps.EMAIL}>
        <label htmlFor="csa-app-email">What's your e-mail address?</label>
        <div className="input-group">
          <input id="csa-app-email" className={'form-control ' + (emailError && 'error')} type="text" ref={this.emailRef} placeholder="For availability alerts!" 
            defaultValue={this.context.email}
            onBlur={this.onEmail} 
            onKeyPress={e => (e.key == 'Enter') && this.onEmail()}
            {...noAutoComplete}
          />
          <div className="input-group-append">
            <button className="btn btn-secondary" type="button" onClick={this.onEmail}>&gt;</button>
            {
              // JOHN D
              // clicking the email button is buggy
              // it goes to phone but quickly comes back... 
              // ALSO
              // hitting the enter key does show the correct next step
              // but the user remains focused on the email input 
              // we don't need to auto-focuse the text input
              // but we should blur this email input 
            }
          </div>
        </div>
        {emailError && <p className='form-text text-danger'>Please enter a valid Email Address</p>}
        {!emailError && <p className='form-text'>We will keep it private &ndash; no spam ever</p>}
      </AppStep>
    )
  }
}
EmailStep.contextType = CampContext;