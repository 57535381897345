import React, { Component, useState, useEffect } from 'react';
import UserContext from '../contexts/UserContext.js';
import MaskedInput from 'react-maskedinput'
import EmailValidator from 'email-validator'
import CampProvider from '../providers/CampProvider';
import { post } from '../ajax.js';
import BlockUI from 'react-block-ui';
import noAutoComplete from '../lib/noAutoComplete.js';
import 'react-block-ui/style.css';
import PhoneInput from 'react-phone-number-input'
import C from 'classnames';
import {CardElement, injectStripe} from 'react-stripe-elements';
import Spinner from '../components/Spinner.js';
import { ThemeProvider } from 'styled-components';
import { withRouter } from 'react-router';
import { withUser } from '../providers/UserProvider.js';

class SubscriptionForm extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      subscription_type: props.subscription_type,
      price: props.subscription_type == 'month' ? 4 : 40,
      pending: false,
      consent: false
    };
  }

  componentDidMount() {
    this.setState({
        subscription_type: this.props.subscription_type,
        price: this.props.subscription_type == 'month' ? 4 : 40
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.subscription_type !== this.state.subscription_type) {
      this.setState({
        subscription_type: nextProps.subscription_type,
        price: nextProps.subscription_type == 'month' ? 4 : 40
      });
    }
  }

  submit = async (e) => {
    this.setState({pending: true});

    let token = null;

    let stripe_response = await this.props.stripe.createToken({});
    console.log("STRIPE: GOT RESPONSE: ", stripe_response);
    const { error } = stripe_response;
    // error.message
    if (error) {
      this.setState({error: `Error: ${error.message}`, pending: false});
      return;
    }
    token = stripe_response.token;

    let data = {
      token,
      email: this.props.email,
      ...this.state
    }

    const json = await this.context.onPurchaseMembership(data);
    if (json.ok) {
      const successMessage = 'Membership Successfully Created!'
      this.props.handleToUpdate(json.user, successMessage);
      this.props.user.onJson(json.user);
      this.setState({
        error: null,
        pending: false
      });
    } else if (json.error) {
      this.setState({error: json.error, pending: false});
      return;
    }

    this.props.history.push({
      pathname: "/membership"
    });
  }

  onToggle = e => {
    this.setState({consent: !this.state.consent})
  }

  onLogout = (e) => {
    e.preventDefault();
    this.context.onLogout().then(json => {
      this.props.history.push({
        pathname: "/login",
        search: "?success=You're+now+logged+out"
      });
    });
  }

  render() {
    const desktopStyle = {
      base: {
        fontFamily: 'Open Sans, Segoe UI, sans-serif',
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '36px',
        padding: '10px 20px',
        color: '#666',
      },
      invalid: {
        color: '#D62828',
      },
      complete: {
        color: '#7cb24f',
      },
    };
    const mobileStyle = {
      base: {
        fontFamily: 'Open Sans, Segoe UI, sans-serif',
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '24px',
        padding: '8px 12px',
        color: '#666',
      },
      invalid: {
        color: '#D62828',
      },
      complete: {
        color: '#7cb24f',
      },
    };
    const style = window.innerWidth >= 768 ? desktopStyle : mobileStyle;

    return (
      <div className="text-center mt-3">
        <div id="csp-email-campsite-pro">
          <h2>Your e-mail address</h2>
          <p><span><strong>{this.props.email}</strong></span> <a href="#" onClick={this.onLogout}>Logout to change</a></p>
        </div>

        <div id="csp-payment-campsite-pro">
          <h2>Enter your payment information</h2>

          <div className="stripe-holder">
            <CardElement style={style} />
          </div>
          <p className="form-text"><i id="csa-app-icon-lock"></i> Your information is secure</p>

          <div key="consent" id="csp-payment-campsite-pro-consent" className="custom-control custom-control-lg custom-checkbox d-inline-flex">
            <input id="consent" type="checkbox" className="custom-control-input" onClick={this.onToggle} />
            <label className="custom-control-label" htmlFor="consent">{this.state.subscription_type ? `I consent to a ${this.state.subscription_type} charge of $${this.state.price}` : 'Select Membership Plan'}</label>
          </div>
        </div>

        <div id="csa-app-last">
          <button className="btn btn-lg btn-primary" onClick={this.submit} disabled={!(this.state.subscription_type && this.state.consent)} ><Spinner loading={this.state.pending} />{this.state.subscription_type ? `Pay $${this.state.price} to Start Membership` : 'Select Membership Plan'}</button>
          <p className="form-text mt-3 mb-5"><em>Cancel at any time.</em></p>
          <p><small>*Excluding embedded afflicate links, promos and offers, as well as direct placement ads.</small></p>
        </div>
      </div>
    )
  }
}


class HaveSubscription extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
  }

  onCancelMembership = async (e) => {
    e.preventDefault();

    let data = {
      email: this.context.email,
      subscription_type: this.context.subscription.subscription_type
    }

    const json = await this.context.onCancelMembership(data);
    if (json.ok) {
      const successMessage = 'Your Campsite PRO Membership has been Cancelled'
      this.props.user.onJson(json.user);
      this.props.handleToUpdate(json.user, successMessage);
    } else if (json.error) {
      this.setState({error: json.error, pending: false});
      return;
    }

    this.props.history.push({
      pathname: "/membership"
    });
  }

  onChangePlan = async () => {
    let data = {
      email: this.context.email,
      subscription_type: this.context.subscription.subscription_type == 'month' ? 'yearly' : 'monthly',
      price: this.context.subscription.subscription_type == 'month' ? 40 : 4
    }

    const json = await this.context.onUpdateMembership(data);
    if (json.ok) {
      const successMessage = 'Membership Plan Successfully Updated!'
      this.props.user.onJson(json.user);
      this.props.handleToUpdate(json.user, successMessage);
    } else if (json.error) {
      this.setState({error: json.error, pending: false});
      return;
    }

    this.props.history.push({
      pathname: "/membership"
    });
  }

  render() {
    const planPrice = (subscription_type) => {
      if (subscription_type == 'month')
        return('$4 per month')
      else
      return('$40 per year')
    }

    const changePlan = (subscription_type) => {
      if (subscription_type == 'month') {
        return(<div><a className="csa-app-membership-change-plan" onClick={this.onChangePlan}>Change to Yearly</a> <span>({planPrice('year')})</span></div>)
      }
      else {
        return(<div><a className="csa-app-membership-change-plan" onClick={this.onChangePlan}>Change to Monthly</a> <span>({planPrice('month')})</span></div>)
      }
    }

    const toLocaleDateString = (membership_date) => {
      const date = new Date(membership_date)
      return (date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }))
    }

    const nextPaymentDate = (membership_date = null) => {
      let date = new Date(this.props.subscription.last_paid_at)
      if (this.props.subscription.subscription_type == 'month') {
        const month = date.getMonth()
        date.setMonth(month + 1)
      } else {
        const year = date.getFullYear()
        date.setFullYear(year + 1)
      }

      return (date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }))
    }

    return (
      <div>
        <div id="csa-users-header" className="d-flex flex-wrap justify-content-center align-items-center">
          <h1 className="mx-0"><strong>Campsite PRO</strong></h1>
        </div>
        <div id="csp-sub-holder">
          <h2 className="text-center">Your Subscription</h2>
          <div className="d-flex" style={{justifyContent: "space-between"}}>
            <div>
              <h3 className="text-capitalize">{this.props.subscription.subscription_type}ly 
              <a href="" onClick={(e) => {e.preventDefault(); }}> {planPrice(this.props.subscription.subscription_type)}</a></h3>
              <p><small>Subscribed {toLocaleDateString(this.props.subscription.purchased_at)}</small></p>
            </div>
            <div>
              <strong>{changePlan(this.props.subscription.subscription_type)}</strong>{this.props.username}
            </div>
          </div>
          <div className="d-flex text-left mt-4" style={{justifyContent: "space-between"}}>
            <div>
              <h4>Next Bill Date</h4>
              <p><strong>{nextPaymentDate()}</strong></p>
            </div>
            <div><a className="btn btn-sm btn-light" href="" onClick={this.onCancelMembership}>Cancel Subscription</a></div>
          </div>
        </div>
        <div id="csp-pymts-holder">
          <div className="text-center"><h2>Recent Payments</h2></div>
          {(this.props.subscription.payment_history || []).length > 0 && (
            <div className="csa-scan-alerts">
              <ul>
                {this.props.subscription.payment_history.map(payment => <li key={payment}>{payment}</li>)}
              </ul>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default class CampsiteProPage extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.accountVars = [ "username", "firstname", "lastname", "phone", "email", "subscription" ].concat(CampProvider.getFilterVars())
    this.state = {
      errors: {},
      blocking: false,
      subscriptionSelected: false,
      subscriptionType: null,
      subscriptionStatus: '',
      ...this.readContext()
    };
  }

  readContext() {
    const ctx = this.context || {};
    let state = {};
    this.accountVars.forEach(v => state[v] = ctx[v] || "");

    if (ctx) {
      if (state.user) {
        state['subscriptionStatus'] = state.user.subscription.status
      } else if (state.subscription) {
        state['subscriptionStatus'] = state.subscription.status
      } else if (ctx.subscription) {
        state['subscriptionStatus'] = ctx.subscription.status
      }
    }

    return state;
  }

  componentDidMount() {
    if (!this.context.isLoggedIn()) {
      this.props.history.push({ pathname: "/login", previous: "/membership" });
    } else {
      this.setState(this.readContext());

      let params = new URLSearchParams(this.props.location.search);
      if (params.has("success")) {
        this.setState({ success: params.get("success") });
      }

    }
  }

  handleToUpdate(user, successMessage){
    this.setState({subscription: user.subscription, success: successMessage});
  }

  onChange = (e) => {
    if (this.ckb.includes(e.target.name)) {
      let name = e.target.name;
      this.setState(ps => ({ [name]: !ps[name] }));
    } else this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const events = {
      onChange: this.onChange,
    };

    const subscriptionClicked = (element, type) => {
      const parent = element.target.parentNode
      parent.classList.toggle("selected");
      parent.classList.toggle("d-inline-block");

      this.setState({ subscriptionSelected: true, subscriptionType: (parent.classList.contains('selected')) ? type : null });
    }

    const isHaveSubscription = () => {
      const ctx = this.context || {};
      if (ctx.user) {
        return ctx.user.subscription.status == "active" ? true : false
      } else if (this.state.subscription) {
        return this.state.subscription.status == "active" ? true : false
      }
    }

    return (
      <>
        { this.state.success && <div className="alert alert-info">{this.state.success}</div> }
        { isHaveSubscription() && <HaveSubscription user={this.context} history={this.props.history} subscription={this.state.subscription} handleToUpdate={this.handleToUpdate.bind(this)} />}
        { !isHaveSubscription() &&
          <BlockUI tag="div" blocking={this.state.blocking}>
            <div className="text-center">
            <div id="csa-users-header" className="d-flex flex-wrap justify-content-center align-items-center">
              <h1 className="mx-0">Upgrade Account to <strong>Campsite PRO</strong></h1>
            </div>
              <div id="csp-user-campsite-pro">
                
                <h2>Discover Your Next Campsite with Added Benefits</h2>

                <div id="csp-benefits" className="d-flex justify-content-center">
                  <div>
                    <div className="csp-benefit-icon"><i className="csp-benefit-icon-ad-free"></i></div>
                    <h3><span>Ad Free Experience</span>
                    on campsitephotos.com*</h3>
                  </div>
                  <div>
                    <div className="csp-benefit-icon"><i className="csp-benefit-icon-discount"></i></div>
                    <h3><span>20% Discount</span>
                    on campsite availability alerts</h3>
                  </div>
                  <div>
                    <div className="csp-benefit-icon"><i className="csp-benefit-icon-small-biz"></i></div>
                    <h3><span>Support a Small Business</span>
                    &amp; help fund more photo trips</h3>
                  </div>
                </div>

                <h2>Select your payment option</h2>

                <div id="csa-scan-type-btns" className="row justify-content-center">
                  <div id="" className={C(
                    "col-auto text-center subscription_type",
                    { 'd-inline-block': this.state.subscriptionType == "year" },
                    { selected: this.state.subscriptionType == "month" },
                  )}>
                    <a href="" id="" value={"month"} className="btn btn-secondary" onClick={(e) => {e.preventDefault(); subscriptionClicked(e, 'month') }}>$4 PER MONTH</a>
                    {<p className="form-text">Great Value</p>}
                  </div>
                  <div id="csa-matrix-scan-col" className={C(
                    "col-auto text-center subscription_type",
                    { 'd-inline-block': this.state.subscriptionType == "month" },
                    { selected: this.state.subscriptionType == "year" },
                  )}>
                    <a href="" id="" value={"year"} className="btn btn-secondary" onClick={(e) => {e.preventDefault(); subscriptionClicked(e, 'year') }}>$40 PER YEAR</a>
                    {<p className="form-text">Save $8 per year</p>}
                  </div>
                </div>
              </div>
            </div>
            <div></div>
            { this.state.subscriptionSelected && <StripePaymentStep user={this.context} history={this.props.history} subscription_type={this.state.subscriptionType} email={this.state.email} handleToUpdate={this.handleToUpdate.bind(this)} />}
          </BlockUI>
        }
      </>
    );
  }
}

const StripePaymentStep = injectStripe(withRouter(withUser(SubscriptionForm)));

export { StripePaymentStep, SubscriptionForm };