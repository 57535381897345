import React, { Component } from 'react';
import Recaptcha from 'react-recaptcha';
import MaskedInput from 'react-maskedinput'
import EmailValidator from 'email-validator'
import { post } from '../ajax.js';
import UserContext from '../contexts/UserContext.js';
import Spinner from '../components/Spinner.js';
import BlockUI from 'react-block-ui';
import 'react-block-ui/style.css';

export default class CreateAccountPage extends Component {
  static contextType = UserContext;

  constructor(props) { 
    super(props);
    this.state = {
      username: "",
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      password: "",
      password2: "",
      terms: false,
      errors: {},
      blocking: false
    };
  }

  shouldComponentUpdate() {
    if (this.context.isLoggedIn()) {
      this.props.history.push("/user");
      return false;
    }
    return true;
  }
  
  onChange = (e) => {
    if (e.target.name == "terms") {
      this.setState(ps => { return { terms: !ps.terms } });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  onCreateAccount = () => {
    const state = this.state;
    const required = [ 
      "username", "firstname", "lastname", "phone", "email",
      "password", "password2"
    ];
    let errors = {};
    required.filter(req => !state[req] || state[req].trim().length == 0).forEach(req => errors[req] = "Field is required");
    if (!state.terms) errors.terms = "Please agree to the Terms & Conditions"
    if (!EmailValidator.validate(state.email)) errors.email = "Please enter a valid Email";
    if (state.phone.replace(/\D+/g, '').length != 10) errors.phone = "Please enter a valid Phone"
    if (state.password.trim().length < 6) errors.password = "Please enter at least 6 characters"
    if (state.password != state.password2) errors.password = errors.password2 = "Passwords don't match";

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    /* ELSE TODO SUBMIT */
    const data = required.reduce( (acc,val) => (acc[val] = state[val]) && acc, {});
    this.context.onCreateAccount(data).then(json => {
      let state = { blocking: false };
      if (json.errors) {
        state.errors = json.errors;
        this.setState(state);
      } else {
        this.setState({blocking: false});
        this.props.history.push({
          pathname: "/scans",
          search: "?success=Thank you, your account has been created!"
        });
      }
    });
    this.setState({blocking: true, errors: {}});
  }

  render() {
    const events = {
      onChange: this.onChange
    };
    const field = (name) => Object.assign({}, {
      id: name,
      name: name,
      value: this.state[name],
      className: "form-control " + (this.state.errors[name] ? "error" : "")
    });
    console.log(this.state.terms);
    return (
      <>
        <BlockUI tag="div" blocking={this.state.blocking}>
        <h1>Create User Account</h1>
          <div id="csp-user-create-account">
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="username">Username</label>
                  <input type="text" {...field("username")} placeholder="Username" {...events} />
                  <Error msg={this.state.errors.username} />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label htmlFor="firstname">First Name</label>
                  <input type="text" {...field("firstname")} placeholder="First Name"  {...events} />
                  <Error msg={this.state.errors.firstname} />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label htmlFor="lastname">Last Name</label>
                  <input type="text" {...field("lastname")} placeholder="Last Name" {...events} />
                  <Error msg={this.state.errors.lastname} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col col-sm-4">
                <div className="form-group">
                  <label htmlFor="cellphone">Cell Phone</label>
                  <MaskedInput 
                    {...field("phone")}
                    id="cellphone" 
                    type="tel"
                    mask="(111) 111-1111" 
                    placeholder="Cell Number" maxLength={14} 
                    {...events}
                  />
                  <Error msg={this.state.errors.phone} />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label htmlFor="emailaddr">Email Address</label>
                  <input type="email" {...field("email")} id="emailaddr" placeholder="Email Address" {...events} />
                  <Error msg={this.state.errors.email} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="pwd">Password</label>
                  <input type="password" {...field("password")} id="pwd" placeholder="Password" {...events} />
                  <Error msg={this.state.errors.password} />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label htmlFor="confpwd">Re-Enter Password</label>
                  <input type="password" {...field("password2")} id="confpwd" placeholder="Re-Enter Password" {...events} />
                  <Error msg={this.state.errors.password2} />
                </div>
              </div>
              <div className="col"></div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="terms" checked={this.state.terms} id="user_rules_accept" {...events} />
                    <label className="form-check-label" htmlFor="user_rules_accept">I accept, and agree to abide by the <a target="_blank" href="https://www.campsitephotos.com/terms-and-conditions/">Terms and Conditions</a>.</label>
                    <Error msg={this.state.errors.terms} />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <Recaptcha sitekey="6LfJVUAUAAAAAKVS7e0UabhqCeyyAD8rsXmd9OJu" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <button className="btn btn-primary" onClick={this.onCreateAccount}>Create Account</button>
                </div>
              </div>
            </div>
          </div>
        </BlockUI>
      </>
    );
  }
}

const Error = ({msg}) => msg && (
  <>
    {[].concat(msg).map (m => <p key={m} className="text-danger">{m}</p>)}
  </>
) || null;