import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Redirect, Route, Link } from "react-router-dom";
import CreateScanApp from './apps/CreateScanApp.js';
import CreateMembershipApp from './apps/CreateMembershipApp.js';
import UserApp from './apps/UserApp.js';
import LoginApp from './apps/LoginApp.js';
import UserProvider from './providers/UserProvider.js';
import UserHeader from './components/UserHeader.js';
import {StripeProvider, Elements} from 'react-stripe-elements';
import isProduction from './lib/isProduction'

/* @TODO when dev is done generate a session_id param to prevent duplicate checkouts */
class App extends Component {
  constructor(props) { super(props); }

  render() {
    return (
      <StripeProvider apiKey={
        isProduction ? 
        "pk_live_pb5ZfipL8aYKsKgM3KvvMRpC00LqgIdXpg" :
        "pk_test_z0kOrMrHfPd6rfMq0vCl8r7r006u7iN76w"
      }>
        <Elements>
          <UserProvider>
            <Router>
              <UserHeader />
              <Switch>
                <Redirect from="/create" to="/register" />
                <Redirect from="/forgot" to="/forgot-password" />
                <Redirect from="/users/?" to="/user" />
                <Route path={["/", "/campsite-assist", "/campsite-assist/:camp" ]} exact component={CreateScanApp} />
                <Route path={["/campsite-pro"]} exact component={CreateMembershipApp} />
                <Route path={["/user", "/scans", "/packs", "/register", "/membership"]} component={UserApp} />
                <Route path={["/login", "/forgot-password", "/update-password"]} component={LoginApp} />
              </Switch>
            </Router>
          </UserProvider>
        </Elements>
      </StripeProvider>
   );
  }
}

export default App;
