import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default ({show, onClose, onClick, cta}) => (
  <Modal show={show} onHide={onClose} centered className="csa-modal" id="csa-how-modal">
    <Modal.Header closeButton></Modal.Header>
    <Modal.Body>

      <div className="csa-info-panel">

        <h2>How CAMPSITE <strong>ASSIST</strong> works</h2>

        <div className="row justify-content-center py-0 py-md-3">
          <div className="col-12 col-sm-6 col-md-4 py-2 py-md-3">
            <div className="csa-how-icon"><i className="csa-how-icon-setup"></i></div>
            <h3>Setup Your Scan</h3>
            <p>Where, When, <br/>
            and How Often</p>
          </div>
          <div className="col-12 col-sm-6 col-md-4 py-2 py-md-3">
            <div className="csa-how-icon"><i className="csa-how-icon-run"></i></div>
            <h3>Run Your Scan</h3>
            <p>Monitor for <br />
            Cancelled Reservations</p>
          </div>
          <div className="col-12 col-sm-6 col-md-4 py-2 py-md-3">
            <div className="csa-how-icon"><i className="csa-how-icon-alert"></i></div>
            <h3>Get Alerts</h3>
            <p>Text and Email <br />
            Notifications</p>
          </div>
        </div>

        <div className="text-center pt-3 pt-md-5">
          <a className="btn btn-lg btn-primary" href="" onClick={onClick}>{cta || 'Get Started'}</a>
        </div>

      </div>

    </Modal.Body>
  </Modal>
)