import React, { Component } from 'react';
import {CardElement, injectStripe} from 'react-stripe-elements';
import Spinner from '../../components/Spinner.js';
import MembershipContext, { Steps } from '../../contexts/MembershipContext.js';
import { withRouter } from 'react-router';
import { withUser } from '../../providers/UserProvider.js';
import MembershipAppStep from '../../components/MembershipAppStep'

class PaymentStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pending: false
    }
  }

  /*needPayment = () => {
    const { scanCredits = 0 } = this.props.user;
    const { pack, wantsToPay } = this.context;
    return (pack || !scanCredits || wantsToPay);
  }*/

  submit = async (e) => {
    this.setState({pending: true});

    let token = null;

    //if (this.needPayment()) {
      let stripe_response = await this.props.stripe.createToken({});
      console.log("STRIPE: GOT RESPONSE: ", stripe_response);
      const { error } = stripe_response;
      // error.message
      if (error) {
        this.setState({error: `Error: ${error.message}`, pending: false});
        return;
      }
      token = stripe_response.token;
    //}

    const json = await this.context.onCheckout(token);
    if (json.error) {
      this.setState({error: json.error, pending: false});
      return;
    }

    this.setState({
      error: null,
      pending: false
    });

    this.props.user.onJson(json.user);
    this.context.onStep(Steps.THANKS);
  }

  onToggle = e => {
    this.context.onToggleConsent();
  }

  render() {
    const desktopStyle = {
      base: {
        fontFamily: 'Open Sans, Segoe UI, sans-serif',
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '36px',
        padding: '10px 20px',
        color: '#666',
      },
      invalid: {
        color: '#D62828',
      },
      complete: {
        color: '#7cb24f',
      },
    };
    const mobileStyle = {
      base: {
        fontFamily: 'Open Sans, Segoe UI, sans-serif',
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '24px',
        padding: '8px 12px',
        color: '#666',
      },
      invalid: {
        color: '#D62828',
      },
      complete: {
        color: '#7cb24f',
      },
    };
    const style = window.innerWidth >= 768 ? desktopStyle : mobileStyle;

    const ctx = this.context;
    //const { pack, wantsToPay } = ctx;
    //const { scanCredits = 0 } = this.props.user;
    //const s = num => num == 1 ? '' : 's';

    /*const cta = wantsToPay || !scanCredits ? 
      `Pay $${ctx.getPrice()} to Start Membership` :
      "Use (1) to Start Membership";*/
    const cta = ctx.subscription_type ? `Pay $${ctx.getPrice()} to Start Membership` : 'Select Membership Plan';

    return (
      <div id="csa-app-checkout">
        <MembershipAppStep id="csa-app-payment" idx={Steps.PAYMENT}>
          <div>
{
  //this.needPayment() && (
            <label htmlFor="csa-app-pay">Enter your payment information</label>
//)
}
            {this.state.error && <p className="checkoutError">{this.state.error}</p>}
{
  //this.needPayment() && (
            <div className="stripe-holder">
              <CardElement style={style} />
            </div>
//)
}
            <p className="form-text"><i id="csa-app-icon-lock"></i> Your information is secure</p>

            <div key="consent" className="custom-control custom-control-lg custom-checkbox d-inline-flex my-3 px-4">
              <input id="consent" type="checkbox" className="custom-control-input" checked={ctx.haveConsent()} onClick={this.onToggle} />
              <label className="custom-control-label" htmlFor="consent">{ctx.subscription_type ? `I consent to a ${ctx.subscription_type} charge of $${ctx.getPrice()}` : 'Select Membership Plan'}</label>
            </div>
          </div>
          <div id="csa-app-last" className="csa-app-step">
            <button className="btn btn-lg btn-primary mb-3" onClick={this.submit} disabled={this.state.pending || !ctx.canCheckout()}><Spinner loading={this.state.pending} /> {cta} </button>
            <p className="form-text"><em>Cancel at any time.</em></p>
          </div>
        </MembershipAppStep>
      </div>
    )
  }
}
PaymentStep.contextType = MembershipContext;

const StripePaymentStep = injectStripe(withRouter(withUser(PaymentStep)));

export { StripePaymentStep, PaymentStep };