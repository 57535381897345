import React, { useContext } from 'react';
import ScansPage from '../pages/ScansPage.js';
import PacksPage from '../pages/PacksPage.js';
import BuyPacksPage from '../pages/BuyPacksPage.js';
import AccountPage from '../pages/AccountPage.js';
import LoginPage from '../pages/LoginPage.js';
import CreateAccountPage from '../pages/CreateAccountPage.js';
import UserProvider from '../providers/UserProvider.js';
import UserIdentity from '../components/UserIdentity.js';
import UserContext from '../contexts/UserContext.js';
import CampsiteProPage from '../pages/CampsiteProPage.js';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

export default function UserApp(props) {
  console.log("UserApp: ", props);
  const { location } = props;
  const pathname = "/" + location.pathname.split("/")[1];
  console.log("PATHNAME = ", pathname);

  const { admin } = useContext(UserContext);
  const nav = [ 
    { name: "Campsite Assist", link: "/scans", subnav: [ 
      { name: "Scans", link: '/scans' },
      { name: "Packs", link: '/packs' }
    ]},
    { name: "User Account", link: "/user" },
    { name: "Campsite PRO", link: "/membership" }
  ];

  return (
    <div id="csp-users" className="d-flex flex-grow-1">
      <div className="container-fluid align-self-stretch">
        <div className="row h-100">
          <div className="col col-md-3">
            <div id="csp-users-sidebar">
              <UserIdentity />
              <div id="csp-users-nav">
                <ul>
                  { nav.map (item => (
                    <li className={(pathname == item.link || (item.subnav || []).some(subitem => pathname == subitem.link)) && "active"} key={item.link}>
                      <Link to={item.link}>{item.name}</Link>
                      {item.subnav && (
                        <ul>
                          {item.subnav.map (subitem => (
                            <li key={subitem.link} className={pathname == subitem.link && "active"}><Link to={subitem.link}><a href="">{subitem.name}</a></Link></li>
                          ))}
                        </ul>
                      )}

                      {
                        // [x] JOHN D 
                        // introduce a sub nav 
                        // apply .active class accordingly + keep .active class on parent element 
                      }
                    </li>
                  ))}
                  { admin && <li key="admin"><a target="_blank" href="/wp-admin">WP Admin</a></li> }
                </ul>
              </div>
            </div>
          </div>
          <div className="col col-md-9">
            <div id="csp-users-page">
              <Route path="/user" exact component={AccountPage} />
              <Route path="/login" exact component={LoginPage} />
              <Route path="/forgot-password" exact component={LoginPage} />
              <Route path="/register" exact component={CreateAccountPage} />
              <Route path="/scans" exact component={ScansPage} />
              <Route path="/packs/buy" exact component={BuyPacksPage} />
              <Route path="/packs" exact component={PacksPage} />
              <Route path="/membership" exact component={CampsiteProPage} />
            </div>
          </div>
        </div>
      </div>
    </div> 
  );
}