import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { withRouter, Link } from 'react-router-dom';
import UserContext from '../contexts/UserContext';

const userHeader = document.getElementById('csp-user');

class UserHeader extends Component {
  static contextType = UserContext;

  onLogout = (e) => {
    e.preventDefault();
    this.context.onLogout().then(json => {
      this.props.history.push({
        pathname: "/login",
        search: "?success=You're+now+logged+out"
      });
    });
  }

  render() {
    const user = this.context;
    const isHaveSubscription = () => {
      if (user && user.subscription && user.subscription.status === "active") {
        return true;
      } else {
        return false;
      }
    };
  
    return ReactDOM.createPortal((
      user.isLoggedIn() ? (
        <>
          <Link to="/user">
            {this.context.wp_display_name}
            {isHaveSubscription() && <span className='badge badge-info'>PRO</span>}
          </Link>&nbsp;&bull;&nbsp;
          <a href="#" onClick={this.onLogout}>Logout</a>
        </>
      ) : user.isUnconfirmed() ? (
        <>
          TBD
        </>
      ) : (
        <>
          <Link to="/login">Login</Link>&nbsp;&bull;&nbsp;
          <Link to="/create">Register</Link>
        </>
      )
    ), userHeader);
  }
}

export default withRouter(UserHeader);