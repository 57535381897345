import React, { Component } from 'react';
import MembershipContext, { Steps } from '../../contexts/MembershipContext.js';
import MembershipAppStep from '../../components/MembershipAppStep'
import C from 'classnames';
import { withRouter } from 'react-router';

class ThanksStep extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.gtag('config', 'AW-676853644');
    window.gtag('event', 'conversion', {'send_to': 'AW-676853644/KYBrCNzxv6UDEIzv38IC'});
  }

  onAccount = e => {
    e.preventDefault();
    this.props.history.push({ pathname: "/user" });
  }

  render() {
    const { step, scan } = this.context;
    return (
      <MembershipAppStep id="csa-app-thanks" idx={Steps.THANKS}>
        <h2>Thank you for your order!</h2>
        <p><a onClick={this.onAccount}>Your Account</a> is now updated!</p>
        <p>Enjoy your browsering ad-free and 20% discount on campsite availability alerts.</p>
        <p>If you have not already <a onClick={this.onAccount}>set up your account</a>, please do.</p>
        <div className="pt-5 pb-3 d-flex flex-wrap justify-content-center">
          <a onClick={this.onAccount} className="btn btn-primary">Go to My Account</a>
        </div>
      </MembershipAppStep>
    )
  }
}
ThanksStep.contextType = MembershipContext;

export default withRouter(ThanksStep);